import React, { useState } from 'react';
import axios from 'axios';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AiFillFileExcel } from 'react-icons/ai';
import Modal from 'react-modal'; 
import '../../StylesCSS/uploader.css'; 
import { useUser } from '../../context/UserContext';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { API_BASE_URL } from '../dashboardContent/config'; 



// Style personnalisé pour la modal
const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '10px',
        width: '400px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
};

const ImportFileExcel = ({ setActiveSection }) => {

    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false); // Gérer l'état de la modal
    const [fileExistsModalIsOpen, setFileExistsModalIsOpen] = useState(false); // Gérer l'état de la modal d'existence de fichier

    const { userId } = useUser();


    const handleFileUpload = (event) => {
        const uploadedFiles = Array.from(event.target.files);
        setFileNames(uploadedFiles.map(file => file.name));
        setFiles(uploadedFiles);
    };

    const handleSubmit = async () => {
        if (files.length === 0) {
            alert('Please upload at least one file.');
            return;
        }
    
        let success = true; // Variable pour vérifier si toutes les requêtes réussissent
    
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append('file', files[i]);
            formData.append('userId', userId);  // Ajoute l'ID de l'utilisateur ici
    
            try {
                const response = await axios.post(`${API_BASE_URL}/api/excel/save/${i+1}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(`File ${i+1} saved successfully:`, response.data);
            } catch (error) {
                if (error.response && error.response.status === 409) {  // Si le fichier existe déjà
                    console.error(`File ${i+1} already exists:`, error.response.data);
                    setFileExistsModalIsOpen(true); // Ouvrir la modal d'existence de fichier
                } else {
                    console.error(`Error saving file ${i+1}:`, error);
                }
                success = false; // Marquer l'échec
            }
        }
    
        if (success) {
            setModalIsOpen(true); // Ouvre la modal si tout est OK
        }
    };
    const handleGoBack = (section) => {
        setActiveSection(section);
        console.log("Retour");
      };


    return (
        <div style={{ maxWidth: '600px', margin: 'auto' }}>
            <h2 style={{ textAlign: 'right' }}>تحميل الملفات </h2>
            <main>
                <form
                    onClick={() => document.querySelector(".input-field").click()}
                    className="uploader-form"
                >
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        className="input-field"
                        hidden
                        multiple
                        onChange={handleFileUpload}
                    />
                    {fileNames.length > 0 ? (
                        fileNames.map((fileName, index) => (
                            <div key={index} className="uploaded-row">
                                <AiFillFileExcel color='#1475cf' />
                                <span className='upload-content'>
                                    {fileName}
                                    <MdDelete
                                        onClick={() => {
                                            setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                                            setFileNames(prevFileNames => prevFileNames.filter((_, i) => i !== index));
                                        }}
                                    />
                                </span>
                            </div>
                        ))
                    ) : (
                        <>
                            <MdCloudUpload color='#1475cf' size={60} />
                            <p>Browse Files to upload</p>
                        </>
                    )}
                </form>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
  <button
    onClick={handleSubmit}
    className='submit-button'
    style={{ marginBottom: '10px' }} // Ajouter un espacement entre les boutons
  >
    تاكيد
  </button>
  <button
    onClick={() => handleGoBack('PageLayout')}
    className="backbtn"
  >
    <FontAwesomeIcon icon={faArrowLeft} /> رجوع
  </button>
</div>



                {/* Modal de confirmation */}
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    style={customModalStyles}
                    contentLabel="Confirmation"
                    ariaHideApp={false} // Désactive l'avertissement pour tester (optionnel)
                >
                    <div style={{ textAlign: 'center' }}>
                        <h2 style={{ color: '#1475cf' }}>Succès</h2>
                        <p style={{ fontSize: '16px', marginBottom: '20px' }}>
                            Les données ont été enregistrées avec succès !
                        </p>
                        <button
                            onClick={() => setModalIsOpen(false)}
                            style={{
                                backgroundColor: '#1475cf',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '16px',
                            }}
                        >
                            Fermer
                        </button>
                    </div>
                </Modal>
                <Modal
    isOpen={fileExistsModalIsOpen}
    onRequestClose={() => setFileExistsModalIsOpen(false)}
    style={customModalStyles}
    contentLabel="Erreur"
    ariaHideApp={false}
>
    <div style={{ textAlign: 'center' }}>
        <h2 style={{ color: '#e74c3c' }}>Erreur</h2>
        <p style={{ fontSize: '16px', marginBottom: '20px' }}>
            Le fichier que vous avez importé existe déjà dans la base de données.
        </p>
        <button
            onClick={() => setFileExistsModalIsOpen(false)}
            style={{
                backgroundColor: '#e74c3c',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
            }}
        >
            Fermer
        </button>
    </div>
</Modal>

            </main>
        </div>
    );
};

export default ImportFileExcel;
