import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../dashboardContent/config'; 


const ContactUs = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${API_BASE_URL}/api/contact`, formData);
      toast.success('Message envoyé avec succès !');
      setFormData({ firstName: '', lastName: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>اتصل بنا </h2>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div>
            <label style={{ display: 'block', marginBottom: '5px', textAlign: 'right' }}>: الاسم الشخصي </label>
            <input 
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
          <div>
            <label style={{ display: 'block', marginBottom: '5px', textAlign: 'right' }}>: الاسم العائلي</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
          <div>
            <label style={{ display: 'block', marginBottom: '5px', textAlign: 'right' }}>: البريد الالكتروني </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
          <div>
            <label style={{ display: 'block', marginBottom: '5px', textAlign: 'right' }}>: الموضوع</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
          <div>
            <label style={{ display: 'block', marginBottom: '5px', textAlign: 'right' }}>: مضمون الرسالة</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', minHeight: '150px' }}
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            style={{ padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer' }}
          >
            {loading ? 'Envoi en cours...' : 'Envoyer'}
          </button>
        </form>
        
       {/*  <div style={{ marginTop: '20px', textAlign: 'center', direction: 'rtl', fontSize: '16px', color: '#555' }}>
          <p>أو يمكنكم التواصل معنا عبر البريد الإلكتروني التالي  :  </p>
          <p style={{ fontWeight: 'bold', color: '#007BFF' }}>lotfi.rizk@gmail.com</p>
        </div> */}

        <ToastContainer />
      </div>
    </>
  );
};

export default ContactUs;
