import React, { useState , useEffect } from 'react';
import { MenuItem, FormControl, Select, InputLabel, Box, Typography, Button ,TextField } from '@mui/material';
import { useUser } from '../../context/UserContext';
import axios from 'axios';
import jsPDF from 'jspdf'; 
import '../../StylesCSS/EtudiantsInterieurVille.css';
import 'jspdf-autotable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { API_BASE_URL } from '../dashboardContent/config'; 



const DistributionStudentsOutsideCity = ({ setActiveSection })  => {

  const { userId } = useUser(); 
  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [selectedCode, setSelectedCode] = useState('');
  const [selectedAnneeScolaire, setSelectedAnneeScolaire] = useState('');
  const [InternalInstitution1, setInternalInstitution1] = useState('');
  const [selectedNiveauEtude, setSelectedNiveauEtude] = useState('');

  
  const [selectedDate, setSelectedDate] = useState('');


  const [directorate, setDirectorate] = useState('');
  const [directorate1, setDirectorate1] = useState('');

  const [town, setTown] = useState('');
  const [town1, setTown1] = useState('');


  const [institutions1, setInstitutions1] = useState([]);
  const [selectedInstitution1, setSelectedInstitution1] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedClass1, setSelectedClass1] = useState('');


  const [commonCore1, setCommonCore1] = useState('');
  const [boarding, setBoarding] = useState('');
  const [futureSchool, setFutureSchool] = useState('');
  const [external1, setExternal1] = useState('');



  

  console.log("institution ->",selectedInstitution);
  console.log("selectedCode", selectedCode);
  console.log("selectedDate", selectedDate);


  console.log("institutions1",institutions1);
  console.log("classes",classes);
  console.log("selectedInstitution1",selectedInstitution1);
  console.log("selectedClass",selectedClass);
  console.log("Directorate:", directorate);
  console.log("Town:", town);
  console.log("Common Core:", commonCore1);
  console.log("Boarding:", boarding);
  console.log("Future School:", futureSchool);
  console.log("External:", external1);

      // Récupérer les institutions
      useEffect(() => {
        axios.get(`${API_BASE_URL}/api/excel/institutions/${userId}`)
            .then(response => setInstitutions1(response.data))
            .catch(error => console.error('Erreur lors de la récupération des institutions:', error));
    }, [userId]);

    // Récupérer les classes quand une institution est sélectionnée
    useEffect(() => {
        if (selectedInstitution1) {
            axios.get(`${API_BASE_URL}/api/excel/classes/${userId}/${selectedInstitution1}`)
                .then(response => setClasses(response.data))
                .catch(error => console.error('Erreur lors de la récupération des classes:', error));
        }
    }, [selectedInstitution1, userId]);

  useEffect(() => {
    axios.get(`${API_BASE_URL}/api/excel/institutions/${userId}`)
        .then(response => setInstitutions(response.data))
        .catch(error => console.error('Erreur lors de la récupération des institutions:', error));
}, [userId]);

  const handleSave = async () => {
    const dataToSave = {
        user: { id: userId }, // Assurez-vous d'envoyer l'objet utilisateur avec son id
        institution: selectedInstitution,    
        code: selectedCode, // Ajout de رمزها
        date: selectedAnneeScolaire, // Ajout de تاريخ انعقاد المجلس
    };

    // Sauvegarde des données
    try {
        const saveResponse = await axios.post(`${API_BASE_URL}/api/deuxieme-langue/save`, dataToSave);
        console.log('Données sauvegardées:', saveResponse.data);
        // Vous pouvez ajouter ici une notification de succès ou rediriger l'utilisateur
    } catch (error) {
        console.error('Erreur lors de la sauvegarde des données:', error);
    }
};

const getNombresRepartitionEtudiants = async (selectedInstitution) => {
  const response = await fetch(`${API_BASE_URL}/api/students/Repartition_des_etudiants?selectedInstitution=${selectedInstitution}`);
  const data = await response.json();
  return data; // retourne un objet { filles: x, garcons: y }
};

// Exemple d'utilisation dans ton composant
getNombresRepartitionEtudiants(selectedInstitution).then(data => {
  console.log('countFillesByEnglish1:', data.countFillesByEnglish1);
  console.log('countFillesBySpain1:', data.countFillesBySpain1);
  console.log("somme",data.sommeFilles1 );
  console.log("data",data);
  // Insérer ces valeurs dans le tableau du PDF
});

const getFilteredStudents = async (selectedInstitution1, selectedClass, commonCore) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/students/getStudentsByCommonCore`, {
          params: {
              institution: selectedInstitution1, // Institution sélectionnée
              classe: selectedClass, // Classe sélectionnée
              decisionConseil: commonCore // Common Core (décision conseil)
          }
      });

      console.log('Données des étudiants filtrés:', response.data); // Afficher les données dans la console
      return response.data; // Retourner les données des étudiants filtrés
  } catch (error) {
      console.error('Erreur lors de la récupération des étudiants filtrés:', error);
      return [];
  }
};




const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };

  const handleExportPDF = async () => {

    const students = await getFilteredStudents(selectedInstitution1, selectedClass, commonCore1);
    const studentTableData = students.map((student, index) => ({
      decisionConseil: student.decisionConseil,
      decisionConseil1: student.decisionConseil,
      deuxiemeLangueEtrangere : student.deuxiemeLangueEtrangere,
      noteTotal : student.noteTotal,
      nature : student.nature,
      numeroEleve: student.numeroEleve,
      nomEtPrenom : student.nomEtPrenom,
      num: index + 1
    }));


    const doc = new jsPDF('p', 'pt', 'a4');

    // Ajouter la police arabe
    doc.addFont('/fonts/NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');
    doc.setFont('NotoSansArabic');
    doc.setFontSize(12);

    const img = new Image();
    img.src = '/images/Photo1.PNG';
    await new Promise(resolve => {
      img.onload = resolve;
    });
  
    const imgWidth = 250;
    const imgHeight = (img.height * imgWidth) / img.width;
    doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);
  
    doc.setFont('NotoSansArabic');
  
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
  
    // Ajouter les trois premiers textes avec les nouvelles propriétés
    doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 15, { align: 'center' });
    doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 30, { align: 'center' });
    doc.text('  مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 45, { align: 'center' });
  
    const pageWidth = doc.internal.pageSize.width;
    const centerX = pageWidth / 2;

    doc.setFontSize(10);

    doc.text(' -جدول  -ج-   ' , doc.internal.pageSize.width / 2, imgHeight + 75, { align: 'center' });
    // Obtenir la largeur du texte pour calculer la position de la ligne
    const textWidth = doc.getTextWidth('جدول - ج - ');    
    const textXPosition = (doc.internal.pageSize.width / 2) - (textWidth / 2);
    doc.setLineWidth(2);

    // Dessiner une ligne sous le texte
    doc.line(textXPosition, imgHeight + 80, textXPosition + textWidth, imgHeight + 80);
  
    // Texte sous le titre
    doc.setFontSize(10);
    doc.text(' يعبا هذا الجدول لكل التلاميذ الموجهين المنقولين إلى مؤسسة خارج البلدة ', 300, imgHeight + 90, { align: 'center' });
    doc.text('ويعتبر بمثابة شهادة مغادرة جماعية', 300, imgHeight + 103, { align: 'center' });
  
// Définir la taille de la police
doc.setFontSize(10);

// Texte pour les colonnes principales
doc.text('المؤسسة الأصلية', 400, 192); 
doc.text('المؤسسة المستقبلة', 130, 192);

// Ajouter les lignes du tableau (haut et bas du cadre)
doc.setLineWidth(1);
doc.line(40, 175, 550, 175); // ligne supérieure du cadre principal
doc.line(40, 330, 550, 330); // ligne inférieure qui inclut tous les champs (ajuster la position pour inclure les sous-champs)

// Ajouter les lignes verticales du cadre
doc.line(40, 175, 40, 295);  // ligne gauche du cadre (du haut jusqu'en bas)
doc.line(300, 175, 300, 295); // ligne séparant les deux colonnes (du haut jusqu'en bas)
doc.line(550, 175, 550, 295); // ligne droite du cadre (du haut jusqu'en bas)


// Sous-champs pour المؤسسة المستقبلة
doc.text(' :  المديرية الاقليمية ', 200, 215); // à gauche
doc.text(`${directorate1}`, 200, 215, { align: 'right' }); // Valeur de commonCore à droite

doc.text(': البلدة ', 250, 230); // ligne suivante
doc.text(`${town1}`, 245, 230, { align: 'right' }); // Valeur de boarding à droite

doc.text(': المؤسسة الخارجية', 200, 245); 
doc.text(`${InternalInstitution1}`, 195,  245, { align: 'right' }); // Valeur de futureSchool à droite

doc.text(': المؤسسة الداخلية', 203, 260); 
doc.text(`${external1}`, 195, 260, { align: 'right' }); // Valeur de external à droite

doc.text(': القسم و الشعبة', 210, 275); 
doc.text(` ${commonCore1} + ${selectedClass1} `, 205, 275, { align: 'right' });


// Sous-champs pour المؤسسة الأصلية
doc.text(' : المديرية الاقليمية', 460, 215);
doc.text(`${directorate}`, 450 , 215, { align: 'right' }); // Valeur de directorate à droite

doc.text(' : البلدة ', 506, 230);
doc.text(`${town}`, 490, 230, { align: 'right' }); // Valeur de town à droite

doc.text(' : المؤسسة', 490, 245);
doc.text(`${selectedInstitution1}`, 475, 245, { align: 'right' }); // Valeur de selectedInstitution1 à droite

doc.text(' : المستوي الدراسي', 458, 260);
//doc.setFont('NotoSans');
doc.text(`${selectedNiveauEtude}`, 450, 260, { align: 'right' }); // Valeur de selectedClass à droite

doc.text(' : القسم', 506, 275);
doc.setFont('NotoSans');
doc.text(`${selectedClass}`, 485, 275, { align: 'right' }); // Valeur de selectedInstitution1 à droite
doc.setFont('NotoSansArabic'); 



// Ajouter les lignes horizontales supplémentaires pour séparer les sous-champs
doc.line(40, 200, 550, 200); // ligne entre les titres et les sous-champs
doc.line(40, 295, 550, 295); // ligne après le dernier sous-champ



  // Ajout du tableau (comme dans l'exemple précédent)
const tableData = Array.from({ length: 40 }, (_, i) => [
  i + 1, '', '', '', '', '', ''
]);

// Définir les colonnes du tableau
const columns = [
  { header: 'ابتداء من', dataKey: 'newValue' },
  { header: 'القيمة الجديدة', dataKey: 'newValue' },
  { header: 'القيمة القديمة', dataKey: 'oldValue' },
  { header: 'رقم المنحة', dataKey: 'numBourse' },
  { header: 'الرقم بمحضر التوجيه', dataKey: 'rank' },
  { header: 'القسم', dataKey: 'classe' },
  { header: 'الجنس', dataKey: 'nature' },
  { header: 'رمز مسار', dataKey: 'codemassar' },
  { header: 'اسم التلميذ&nbsp;(&#x0629;)', dataKey: 'name' },
  { header: ' ر.ت', dataKey: 'num' }
];
console.log("Contenu de studentTableData:", studentTableData);

doc.autoTable({
  startY: 300, // Commence à 280px en haut pour la première page
  head: [columns.map(col => col.header)],
  body: studentTableData.map(row => [
    '',
    '',
    '',
    '',
    '',
    '',
    row.nature,
    row.numeroEleve,
    row.nomEtPrenom,
    row.num
  ]),

  styles: {
    font: 'NotoSansArabic',
    halign: 'right',
    fontSize: 10,
  },
  columnStyles: {
    0: { cellWidth: 50},  // Ajuste la largeur des colonnes pour 'قرار المجلس'
    1: { cellWidth: 50 },  // Ajuste la largeur des colonnes pour 'قرار المجلس'
    2: { cellWidth: 50 },  // Ajuste la largeur des colonnes pour 'قرار المجلس'
    3: { cellWidth: 50 },  // Ajuste la largeur des colonnes pour 'قرار المجلس'
    4: { cellWidth: 55},  // 'الرتبة'
    5: { cellWidth: 50 }, // 'اللغة الأجنبية الثانية'
    6: { cellWidth: 45},  // 'معدل النجاح'
    7: { cellWidth: 70 }, // 'الرقم بجدول التوجيه'
    8: { cellWidth: 100 }, // 'الاسم الكامل'
    9: { cellWidth: 30 },  // 'رقم ترتيبي'
  },
  margin: { top: 280, right: 20, left: 20 }, // Marge pour la premتاière page
  didDrawPage: function (data) {
    
    if (data.pageNumber === 1) {
      // Marge pour la première page
      data.settings.margin.top = 21; 
    } else {
      // Marge pour les pages suivantes
      data.settings.margin.top = 10; 
    }

    // Ajuster la marge après la 23ème ligne
    const lastRowIndex = data.cursor.index; // Obtenir l'index de la dernière ligne dessinée
    if (lastRowIndex === 22) { // 22 car les index commencent à 0
      data.settings.margin.top = 50; // Changer la marge selon le besoin
    }
  },
  didParseCell: function (data) {
    // Appliquer la police 'NotoSans' uniquement aux données de la colonne 'numeroEleve'
    if (data.section === 'body' && data.column.index === 7) { // Vérifie que c'est une donnée du body
      data.cell.styles.font = 'NotoSans';
    }
  }
});
const totalPages = doc.internal.getNumberOfPages();
console.log("total number", totalPages);

for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
  doc.setPage(pageNumber);

  // Position de la ligne de bas de page
  const pageHeight = doc.internal.pageSize.height;
  const footerYPosition = pageHeight - 150; // Ajuste la position verticale ici (30 mm du bas)

  // Vérifiez si nous sommes sur la dernière page
  if (pageNumber === totalPages) {
    // Dessiner le texte de bas de page uniquement sur la dernière page
    doc.text(' ............. : حـــرر بــ : .............. في', pageWidth / 2, footerYPosition, { align: 'center' });
    const notesTableData = [
      ['خاتم وتوقيع  رئيس المصلحة', 'خاتم وتوقيع رئيس المؤسسة'], // Ligne des titres
      ['', ''] // Ligne vide pour les signatures
     ];
  
  // Options pour centrer le tableau au bas de la page
  doc.autoTable({
    head: [notesTableData[0]], // En-tête du tableau
    body: [notesTableData[1]], // Contenu du tableau (lignes vides)
    startY: doc.internal.pageSize.height - 120, // Positionner le tableau en bas de la page
    styles: {
      font: 'NotoSansArabic', // Police arabe
      fontSize: 10,
      halign: 'center', // Centrer horizontalement
      cellPadding: 5, // Espacement dans les cellules
    },
    theme: 'grid', // Bordures du tableau
    headStyles: {
      fillColor: [240, 240, 240], // Couleur de fond des en-têtes
      textColor: [0, 0, 0], // Couleur du texte des en-têtes
    },
    columnStyles: {
      0: { cellWidth: (pageWidth - 40) / 2, halign: 'center' }, 
      1: { cellWidth: (pageWidth - 40) / 2, halign: 'center' }, 
    },
    margin: { top: 0, right: 20, bottom: 0, left: 20 },
  });
  }
}
    // Enregistrer le PDF
    doc.save(' جدول ج مكرر.pdf');
  };


  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: 2, 
        padding: '2rem', 
        backgroundColor: '#f4f6f8', // Couleur de fond douce
        borderRadius: '10px', 
        border: '1px solid #dcdcdc',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '700px',
        margin: 'auto',
        textAlign: 'right',
      }}
    >
      <Typography 
        variant="h5" 
        sx={{ 
          marginBottom: '1rem', 
          fontWeight: '500', 
          color: '#2c3e50',
          textAlign: 'center'
        }}
      >
جدول ( ج )                     </Typography>

      <div className="container1">
      <div className="box1 future1">
        <h3> المؤسسة المستقبلة</h3>
        <label htmlFor="directorate">: المديرية الاقليمية</label>
        <input
          type="text"
          id="directorate"
          name="directorate"
          placeholder="أدخل المديرية الاقليمية"
          value={directorate1}
          onChange={(e) => setDirectorate1(e.target.value)}
          style={{ textAlign: 'right' }} 
        />
         <label htmlFor="town">: البلدة </label>
        <input
          type="text"
          id="town"
          name="town"
          placeholder="أدخل البلدة"
          value={town1}
          onChange={(e) => setTown1(e.target.value)}
          style={{ textAlign: 'right' }} 

        />    

        <label htmlFor="InternalInstitution"> :المؤسسة الخارجية </label>
        <input
          type="text"
          id="InternalInstitution"
          name="InternalInstitution"
          placeholder="أدخل المؤسسة الخارجية"
          value={InternalInstitution1}
          onChange={(e) => setInternalInstitution1(e.target.value)}
          style={{ textAlign: 'right' }} 

        /> 
         <label htmlFor="external"> :المؤسسة الداخلية </label>
        <input
          type="text"
          id="external"
          name="external"
          placeholder="أدخل المؤسسة الداخلية"
          value={external1}
          onChange={(e) => setExternal1(e.target.value)}
          style={{ textAlign: 'right' }} 

        />    
        <label htmlFor="selectedClass"> : القسم </label>
        <input
          type="text"
          id="selectedClass"
          name="selectedClass"
          placeholder="أدخل القسم "
          value={selectedClass1}
          onChange={(e) => setSelectedClass1(e.target.value)}
          style={{ textAlign: 'right' }} 

        />     
        <label htmlFor="common-core"> : الجدع المشترك</label>
<select
  id="common-core"
  name="common-core"
  value={commonCore1}
  onChange={(e) => setCommonCore1(e.target.value)}
  style={{ textAlign: 'right' }}
>
  <option value="">أدخل الجدع المشترك</option>
  <option value="الجذع المشترك للتعليم الأصيل">الجذع المشترك للتعليم الأصيل</option>
  <option value="الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية">
    الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية
  </option>
  <option value="الجذع المشترك للآداب والعلوم الإنسانية">الجذع المشترك للآداب والعلوم الإنسانية</option>
  <option value="الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية">
    الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية
  </option>
  <option value="الجذع المشترك العلمي للمسالك الدولية للبكالوريا المغربية">
    الجذع المشترك العلمي للمسالك الدولية للبكالوريا المغربية
  </option>
  <option value="الجذع المشترك العلمي">الجذع المشترك العلمي</option>
  <option value="الجذع المشترك العلمي تخصص تربية بدنية">الجذع المشترك العلمي تخصص تربية بدنية</option>
  <option value="الجذع المشترك التكنولوجي">الجذع المشترك التكنولوجي</option>
  <option value="الجذع المشترك الصناعي">الجذع المشترك الصناعي</option>
  <option value="الجذع المشترك الخدماتي">الجذع المشترك الخدماتي</option>
</select>
        </div>
      {/* Div pour الموسسة الاصلية */}
      <div className="box1 original1">
        <h3> المؤسسة الأصلية</h3>
        <label htmlFor="directorate">: المديرية </label>
        <input
          type="text"
          id="directorate"
          name="directorate"
          placeholder="أدخل المديرية"
          value={directorate}
          onChange={(e) => setDirectorate(e.target.value)}
          style={{ textAlign: 'right' }} 
        />

        <label htmlFor="town">: البلدة </label>
        <input
          type="text"
          id="town"
          name="town"
          placeholder="أدخل البلدة"
          value={town}
          onChange={(e) => setTown(e.target.value)}
          style={{ textAlign: 'right' }} 

        />
        <label htmlFor="original-school" > : المؤسسة   </label>
        <select className="select" onChange={(e) => setSelectedInstitution1(e.target.value)} value={selectedInstitution1}>
                <option value="" style={{ textAlign: 'right' }} >-- اختر --</option>
                {institutions && institutions.length > 0 && institutions.map(institution => (
                    <option style={{ textAlign: 'right' }}  key={institution} value={institution}>{institution}</option>
                ))}
            </select>
        <label htmlFor="town">: المستوى الدراسي </label>
        <input
          type="text"
          id="town"
          name="town"
          placeholder="  أدخل المستوى الدراسي "
          value={selectedNiveauEtude}
          onChange={(e) => setSelectedNiveauEtude(e.target.value)}
          style={{ textAlign: 'right' }} 

        />
          <label htmlFor="department">القسم :</label>
        <>
                    <select className="select" onChange={(e) => setSelectedClass(e.target.value)} value={selectedClass}>
                        <option value="" style={{ textAlign: 'right' }} >-- اختر --</option>
                        {classes && classes.length > 0 && classes.map(classe => (
                            <option style={{ textAlign: 'right' }}  key={classe} value={classe}>{classe}</option>
                        ))}
                    </select>
        </>
      </div>
    </div>

          {/* Boutons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
       
          <Button 
         variant="outlined" 
         color="secondary" 
         onClick={() => handleGoBack('PageLayout3')}
         sx={{ 
            borderRadius: '8px', 
            borderColor: '#e74c3c', // Couleur de la bordure rouge
            color: '#e74c3c', // Couleur du texte
            padding: '8px 16px', // Ajustez les marges internes
            fontSize: '14px', // Ajustez la taille du texte
            '&:hover': {
                 backgroundColor: '#f8d7da', // Couleur au survol
            }
          }}
          startIcon={<ArrowBackIcon />} // Ajoute l'icône au début du texte
          >
           رجوع     
          </Button>

        
        <button
  onClick={handleExportPDF}
  className="btn btn-secondary"
  style={{
    width: '120px', // Largeur fixe
    height: '50px', // Hauteur fixe
    padding: '10px 20px', // Marges internes pour ajuster la taille
    fontSize: '12px', // Taille du texte
    borderRadius: '8px', // Coins arrondis
  }}
>
  PDF تصدير إلى
</button>
     </Box>
    </Box>

  );
};

export default DistributionStudentsOutsideCity;
