import React, { useState, useEffect } from 'react'; 
import '../../StylesCSS/LeSeuil.css'; 
import axios from 'axios';
import { useUser } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../dashboardContent/config'; 



const LeSeuil = ({ setActiveSection }) => {

  const { userId } = useUser(); 
  const [seuil, setSeuil] = useState('');
  const [seuilConfirme, setSeuilConfirme] = useState(null); // Stocke la valeur confirmée
  const [isEditing, setIsEditing] = useState(true); // Contrôle si l'utilisateur est en mode modification
  const [isUpdating, setIsUpdating] = useState(false); // Indique si nous mettons à jour

  // Récupérer le seuil existant à la première connexion
  useEffect(() => {
    const fetchSeuil = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/seuil/${userId}`);
        if (response.data && response.data.seuil !== null) {
          setSeuilConfirme(response.data.seuil); // Assure-toi que la réponse contient un champ 'seuil'
          setIsEditing(false); // L'utilisateur ne peut pas éditer s'il a déjà un seuil
        }
        else{
          setSeuil(''); // Réinitialiser le seuil si aucun seuil n'existe
          setIsEditing(true); // Activer le mode édition
        }
      } catch (error) {
        
        console.error('Erreur lors de la récupération du seuil:', error);
      }
    };

    fetchSeuil();
  }, [userId]);

  const handleConfirm = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('seuil', parseFloat(seuil));  // Conversion en nombre
      formData.append('userId', userId);

      if (isUpdating) {
        // Si nous sommes en mode mise à jour
        await axios.put(`${API_BASE_URL}/api/seuil/update`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        console.log("عتبة النجاح mise à jour");
      } else {
        // Si c'est la première sauvegarde
        await axios.post(`${API_BASE_URL}/api/seuil/save`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        console.log("عتبة النجاح confirmée");
      }

      setSeuilConfirme(seuil); // Stocker la valeur confirmée
      setSeuil(seuil); 
      setIsEditing(false); // Désactiver la modification après confirmation
      setIsUpdating(false); // Désactiver le mode mise à jour
    } catch (error) {
      console.error('Erreur lors de la sauvegarde/mise à jour du seuil:', error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true); // Réactiver la modification
    setIsUpdating(true); // Indiquer que nous sommes en mode mise à jour
  };
  const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };
  

  

  return (
    <div style={{ maxWidth: '600px', margin: 'auto' }}>
      <h2 style={{ textAlign: 'right' }}> : عتبة النجاح </h2>
      
      <div className="seuil-container">
        {/* Si l'utilisateur est en mode édition */}
        {isEditing ? (
          <>
            <h2>عتبة النجاح</h2>
            <input
              type="number"
              placeholder="أدخل عتبة النجاح"
              className="seuil-input"
              value={seuil}
              onChange={(e) => setSeuil(e.target.value)}
            />
            <div className="button-container">
            <button onClick={() => handleGoBack('PageLayout2')} className="back-btn">
            <FontAwesomeIcon icon={faArrowLeft} /> رجوع

            </button>
            <button onClick={handleConfirm} className="confirm-btn">تاكيد</button>
            </div>
          </>
        ) : (
          <>
            {/* Affichage du seuil confirmé */}
            <h2>عتبة النجاح التي تم ادخالها هي : {seuilConfirme}</h2>
            <div className="button-container">
            <button onClick={() => handleGoBack('PageLayout2')} className="back-btn">
            <FontAwesomeIcon icon={faArrowLeft} /> رجوع</button>
            <button onClick={handleEdit} className="confirm-btn">تعديل</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeSeuil;
