import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { API_BASE_URL } from '../dashboardContent/config'; 

import '../../StylesCSS/StudentSelector.css';  // Import du fichier CSS


const StudentSelector = ({ setActiveSection }) => {
    const { userId } = useUser(); 
    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitution, setSelectedInstitution] = useState('');
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [students, setStudents] = useState([]);
    const [currentStudentIndex, setCurrentStudentIndex] = useState(0);
    const [excelFileId, setExcelFileId] = useState(null);
    const [seuil, setSeuil] = useState(0);


    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/seuil/${userId}`)
            .then(response => {
                setSeuil(response.data.seuil); // Récupère la valeur du seuil
                console.log("Seuil récupéré:", response.data.seuil); // Affiche la valeur du seuil récupérée
            })
            .catch(error => console.error('Erreur lors de la récupération du seuil:', error));
    }, [userId]);
    
    console.log("seuil",seuil);
    // Nouveaux états pour les inputs
    const [decisionConseil, setDecisionConseil] = useState('');
    const [langueEtrangere, setLangueEtrangere] = useState('');
    console.log("decisionConseil",decisionConseil);
    console.log("langueEtrangere",langueEtrangere);

    useEffect(() => {
        if (students.length > 0) {
            const currentStudent = students[currentStudentIndex];
            console.log("currenntStudent",currentStudent);
    
            // Récupérer les valeurs de décision du conseil et langue étrangère pour l'étudiant actuel
            axios.get(`${API_BASE_URL}/api/students/${currentStudent.id}`)
                .then(response => {
                    const studentData = response.data;
                    setDecisionConseil(studentData.decisionConseil || ''); // Définir la décision du conseil
                    setLangueEtrangere(studentData.deuxiemeLangueEtrangere || ''); // Définir la langue étrangère
                })
                .catch(error => console.error('Erreur lors de la récupération des données de l\'étudiant:', error));
        }
    }, [currentStudentIndex, students]);
    console.log("Liste des étudiants:", students);
console.log("Index actuel de l'étudiant:", currentStudentIndex);



    // Récupérer les institutions
    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/excel/institutions/${userId}`)
            .then(response => setInstitutions(response.data))
            .catch(error => console.error('Erreur lors de la récupération des institutions:', error));
    }, [userId]);

    // Récupérer les classes quand une institution est sélectionnée
    useEffect(() => {
        if (selectedInstitution) {
            axios.get(`${API_BASE_URL}/api/excel/classes/${userId}/${selectedInstitution}`)
                .then(response => setClasses(response.data))
                .catch(error => console.error('Erreur lors de la récupération des classes:', error));
        }
    }, [selectedInstitution, userId]);

    // Récupérer l'excelFileId lorsque l'institution et la classe sont sélectionnées
    useEffect(() => {
        if (selectedInstitution && selectedClass) {
            axios.get(`${API_BASE_URL}/api/excel/fileId/${userId}/${selectedInstitution}/${selectedClass}`)
                .then(response => setExcelFileId(response.data.excelFileId))
                .catch(error => console.error('Erreur lors de la récupération de l\'ID du fichier Excel:', error));
        }
    }, [selectedInstitution, selectedClass, userId]);

    const fetchStudents = () => {
        if (excelFileId) {
            axios.get(`${API_BASE_URL}/api/students/byExcelFile`, { params: { excelFileId } })
                .then(response => setStudents(response.data))
                .catch(error => console.error('Erreur lors de la récupération des étudiants:', error));
        } else {
            console.error('excelFileId est requis');
        }
    };

    const handleNextStudent = () => {
        if (currentStudentIndex < students.length - 1) {
            const currentStudent = students[currentStudentIndex];

            // Mettez à jour l'entité Student ici si nécessaire
            axios.put(`${API_BASE_URL}/api/students/updateStudent/${currentStudent.id}`, {
                ...currentStudent,
                decisionConseil: decisionConseil,
                deuxiemeLangueEtrangere: langueEtrangere
            })            
            .then(() => {
                setCurrentStudentIndex(currentStudentIndex + 1);
                // Réinitialiser les inputs après avoir changé d'étudiant
                setDecisionConseil('');
                setLangueEtrangere('');
            })
            .catch(error => console.error('Erreur lors de la mise à jour de l\'étudiant:', error));
        }
    };

    const handlePreviousStudent = () => {
        if (currentStudentIndex > 0) {
            setCurrentStudentIndex(currentStudentIndex - 1);
        }
    };
    const handleGoBack = (section) => {
        setActiveSection(section);
        console.log("Retour");
      };

      // Vérifier si students est défini et que currentStudentIndex est valide
const noteTotal = (students.length > 0 && currentStudentIndex >= 0 && currentStudentIndex < students.length)
? parseFloat(students[currentStudentIndex].noteTotal.replace(',', '.'))
: 0; // ou une autre valeur par défaut

const seuilValue = parseFloat(seuil);
console.log("noteTotal)>",noteTotal);
    return (
        <div className="container">
                <button onClick={() => handleGoBack('PageLayout')} className="back-btn">
                <FontAwesomeIcon icon={faArrowLeft} /> رجوع
                    </button>

            <h5 className="title" style={{ textAlign: 'right' }} > : اختر المؤسسة</h5>
            <select className="select" onChange={(e) => setSelectedInstitution(e.target.value)} value={selectedInstitution}>
                <option value="" style={{ textAlign: 'right' }} >-- اختر --</option>
                {institutions && institutions.length > 0 && institutions.map(institution => (
                    <option style={{ textAlign: 'right' }}  key={institution} value={institution}>{institution}</option>
                ))}
            </select>

            {selectedInstitution && (
                <>
                    <h5 className="title" style={{ textAlign: 'right' }} > : اختر القسم</h5>
                    <select className="select" onChange={(e) => setSelectedClass(e.target.value)} value={selectedClass}>
                        <option value="" style={{ textAlign: 'right' }} >-- اختر --</option>
                        {classes && classes.length > 0 && classes.map(classe => (
                            <option style={{ textAlign: 'right' }}  key={classe} value={classe}>{classe}</option>
                        ))}
                    </select>
                </>
            )}

            {selectedClass && (
                <>
    <div className="buttonContainer">
        <button className="button" onClick={fetchStudents}>Afficher les étudiants</button>
    </div>

    {students.length > 0 && (
        <div className="studentInfo">
            <h5 style={{ textAlign: 'right' }} >التلميذ {currentStudentIndex + 1}/{students.length}</h5>
            <br></br>
            <p style={{ textAlign: 'right' }} ><strong>  الاسم الكامل : </strong> {students[currentStudentIndex].nomEtPrenom}</p>
            <p style={{ textAlign: 'right' }} > {students[currentStudentIndex].numeroEleve} <strong> : رقم التلميذ </strong></p>

            <div className="infoBoxContainer">
                {/* Niveau actuel */}
                <div className="infoBox">
                    <p className="infoBoxLabel" > الثالثة</p>
                    <p className="infoBoxValue">{students[currentStudentIndex].niveauActuel}</p>
                </div>
                
                {/* un an avant */}
                <div className="infoBox">
                    <p className="infoBoxLabel">الثانية </p>
                    <p className="infoBoxValue">{students[currentStudentIndex].un_an_avant}</p>
                </div>
                
                {/* deux ans avant */}
                <div className="infoBox">
                    <p className="infoBoxLabel">الاولى</p>
                    <p className="infoBoxValue">{students[currentStudentIndex].deux_an_avant}</p>
                </div>
            </div>
        
            <div className="noteTotalBox">
            <p><strong>المعدل العام  :  </strong> {students[currentStudentIndex].noteTotal}</p>
           </div>
           <br></br>

            <div className="formContainer">
   {/* Décision du Conseil */}
<div className="formGroup">
    <label className="formLabel" style={{ textAlign: 'right' }}>: قرار المجلس</label>
    <select 
        className="formSelect"
        value={decisionConseil} 
        onChange={(e) => setDecisionConseil(e.target.value)}
        style={{ textAlign: 'right' }} 
    >
        <option value="">-- اختر --</option>
        {students.length > 0 && (
            // Afficher les options basées sur noteTotal et seuilValue
            noteTotal > seuilValue ? (
                <>
                    <option value="الجذع المشترك للتعليم الأصيل">الجذع المشترك للتعليم الأصيل</option>
                    <option value="الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية">الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية</option>
                    <option value="الجذع المشترك للآداب والعلوم الإنسانية">الجذع المشترك للآداب والعلوم الإنسانية</option>
                    <option value="الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية">الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية</option>
                    <option value="الجذع المشترك العلمي للمسالك الدولية للبكالوريا المغربية">الجذع المشترك العلمي للمسالك الدولية للبكالوريا المغربية</option>
                    <option value="الجذع المشترك العلمي">الجذع المشترك العلمي</option>
                    <option value="الجذع المشترك العلمي تخصص تربية بدنية">الجذع المشترك العلمي تخصص تربية بدنية</option>
                    <option value="الجذع المشترك التكنولوجي">الجذع المشترك التكنولوجي</option>
                    <option value="الجذع المشترك الصناعي">الجذع المشترك الصناعي</option>
                    <option value="الجذع المشترك الخدماتي">الجذع المشترك الخدماتي</option>
                </>
            ) : (
                <>
                    <option value="يكرر">يكرر</option>
                    <option value="يكرر بقرار">يكرر بقرار</option>
                    <option value="يفصل">يفصل</option>
                </>
            )
        )}
    </select>
</div>



<div className="formGroup">
    <label className="formLabel" style={{ textAlign: 'right' }}> : اللغة الاجنبية الثانية</label>
    <select 
        className="formSelect"
        value={langueEtrangere || ''} // Utilisez une valeur par défaut vide si aucune valeur n'est définie
        onChange={(e) => setLangueEtrangere(e.target.value)}
        style={{ textAlign: 'right' }}
    >
        <option value="">-- اختر --</option>
        
        {/* Afficher la langue actuelle récupérée depuis la base de données */}
        {students.length > 0 && students[0].langueEtrangere && (
            <option value={students[0].langueEtrangere}>
                {students[0].langueEtrangere}
            </option>
        )}

        {/* Les 3 options disponibles pour changer la langue */}
        <option value="اللغة الألمانية">اللغة الألمانية</option>
        <option value="اللغة الإنجليزية">اللغة الإنجليزية</option>
        <option value="اللغة الإسبانية">اللغة الإسبانية</option>
    </select>
</div>



</div>


            <div className="navigationButtons">
                <button 
                    className="button" 
                    onClick={handlePreviousStudent} 
                    disabled={currentStudentIndex === 0}>
                    Précédent
                </button>
                <button 
                    className="button" 
                    onClick={handleNextStudent} 
                    disabled={currentStudentIndex === students.length - 1}>
                    Suivant
                </button>
            </div>
        </div>
    )}
</>

            )}
        </div>
    );
};

export default StudentSelector;
