import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf'; // Importation de jsPDF
import '../../StylesCSS/AllFichesTechniques.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { interventionFields } from '../../utils/interventionFields';
import { activityFields } from '../../utils/activityFields';
import { useUser } from '../../context/UserContext';
import 'jspdf-autotable';
import { API_BASE_URL } from '../dashboardContent/config'; 



const AllFichesTechniques = () => {
  const [ficheTechniques, setFicheTechniques] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedFiche, setSelectedFiche] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [customActivity, setCustomActivity] = useState([]);
  const [filterDate, setFilterDate] = useState(''); 
  const { userId } = useUser();

  const [additionalInfo, setAdditionalInfo] = useState('');
  console.log("selectedfiche", selectedFiche);


  console.log("fichtechniqqs",ficheTechniques);
  useEffect(() => {
    let url = `${API_BASE_URL}/api/fiches-technique/all/${userId}`;
    if (filterDate) {
      url += `?filterDate=${filterDate}`;
    }
  
    axios.get(url)
      .then(response => {
        setFicheTechniques(response.data || []);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des fiches techniques:', error);
      });
  
    axios.get(`${API_BASE_URL}/api/institutions/user/${userId}`)
      .then(response => {
        setInstitutions(response.data || []);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des institutions:', error);
      });
  }, [userId, filterDate]);
  

  const handleEdit = (ficheId) => {
    axios.get(`${API_BASE_URL}/api/fiche-technique/ficheSpecifique/${ficheId}`)
      .then(response => {
        const fiche = response.data;
        if (fiche.institution && typeof fiche.institution === 'object') {
          fiche.institution = fiche.institution.id;
        }
        setSelectedFiche(fiche);
        if (fiche.customActivities && Array.isArray(fiche.customActivities)) {
          setSelectedActivities(fiche.customActivities.map(activity => activity.name || ''));
          setCustomActivity(fiche.customActivities.map(activity => activity.name || '').join(', ')); // Initialisation de customActivity
        } else {
          setSelectedActivities([]);
          setCustomActivity('');
        }
        setShow(true);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des détails de la fiche technique:', error);
      });
  };
  

  const handleDelete = (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette fiche technique ?')) {
      axios.delete(`${API_BASE_URL}/api/fiche-technique/delete/${id}`)
        .then(response => {
          setFicheTechniques(ficheTechniques.filter(fiche => fiche.id !== id));
          console.log('Fiche technique supprimée avec succès');
        })
        .catch(error => {
          console.error('Erreur lors de la suppression de la fiche technique:', error);
        });
    }
  };

  const handleClose = () => {
    setSelectedFiche(null);
    setSelectedActivities([]);
    setShow(false);
  };

  const handleSave = () => {
    const updatedFiche = {
      ...selectedFiche,
      customActivities: [
        ...selectedActivities.map(name => ({
          name,
          user: { id: selectedFiche.user.id },
          ficheTechnique: { id: selectedFiche.id }
        }))
      ]
    };
  
    axios.put(`${API_BASE_URL}/api/fiche-technique/update/${selectedFiche.id}`, updatedFiche)
      .then(response => {
        setFicheTechniques(ficheTechniques.map(fiche =>
          fiche.id === selectedFiche.id ? response.data : fiche
        ));
        handleClose();
        console.log('Fiche technique mise à jour avec succès');
      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de la fiche technique:', error);
      });
  };
  
  

  const handleActivityChange = (activityName) => {
    if (selectedActivities.includes(activityName)) {
      setSelectedActivities(selectedActivities.filter(activity => activity !== activityName));
    } else {
      setSelectedActivities([...selectedActivities, activityName]);
    }
  };

  // Nouvelle fonction pour générer le PDF
  const handleExportPDF = async (fiche) => {
    const doc = new jsPDF();
  
    // Ajouter la police arabe
    doc.addFileToVFS('NotoSansArabic-Regular2.ttf', '/fonts/NotoSansArabic-Regular2.ttf');
    doc.addFont('/fonts/NotoSansArabic-Regular2.ttf', 'NotoSansArabic', 'normal');
  
    // Ajouter une police pour le texte en français
    doc.addFileToVFS('NotoSans-Regular.ttf', '/fonts/NotoSans-Regular.ttf');
    doc.addFont('/fonts/NotoSans-Regular.ttf', 'NotoSans', 'normal');
  
    // Charger l'image
    const img = new Image();
    img.src = '/images/Photo1.PNG';
    await new Promise(resolve => {
      img.onload = resolve;
    });
  
    const imgWidth = 100;
    const imgHeight = (img.height * imgWidth) / img.width;
    doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);
  
    doc.setFont('NotoSansArabic');
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
  
    // Ajouter les trois premiers textes avec les nouvelles propriétés
    doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 15, { align: 'center' });
    doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 20, { align: 'center' });
    doc.text('     مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 25, { align: 'center' });
  
    // Réinitialiser les propriétés pour le texte suivant
    doc.setFontSize(20); // Taille de police par défaut pour le texte suivant
    doc.setTextColor(33, 140, 145); // Couleur par défaut pour le texte suivant
    doc.text('البطاقة التقنية', doc.internal.pageSize.width / 2, imgHeight + 38, { align: 'center' });
  
    const formattedActivities = fiche.customActivities.map(activity => ` ${activity.name}`).join('\n');
  
    const tableData = [
      [fiche.institution, 'اسم المؤسسة'],
      [fiche.section, ' القسم'],
      [fiche.interventionField, 'مجال التدخل'],
      [fiche.beneficiariesCount, 'عدد المستفيدين'],
      [fiche.date, 'التاريخ'],
      [formattedActivities, 'نوع النشاط'],
      [fiche.additionalComments,'ملاحظات']
    ];
    console.log("data à exporter ",tableData);
    doc.autoTable({
      startY: imgHeight + 45,
      head: [['Field', 'Value']],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 12 },
      columnStyles: {
        0: { cellWidth: 'auto', halign: 'right', font: 'NotoSansArabic' },  // Colonne 0 avec texte arabe
        1: { cellWidth: 50, halign: 'right', font: 'NotoSansArabic' }        // Colonne 1 avec texte français
      },
      headStyles: {
        fillColor: [33, 140, 145],
        font: 'NotoSansArabic',
        textColor: [255, 255, 255]
      },
      bodyStyles: {
        font: 'NotoSansArabic'
      }
    });
  
    // Ajouter les zones de signature à la fin du document
    const pageHeight = doc.internal.pageSize.height;
    const signatureY = pageHeight - 30; // Position Y pour les signatures

    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
    // Signature à droite
    doc.text(': توقيع اطار التوجيه التربوي', doc.internal.pageSize.width - 20, signatureY, { align: 'right' });
  
    // Signature à gauche
    doc.text(': توقيع مدير الموسسة', 20, signatureY, { align: 'left' });
  
    doc.save('fiche_technique.pdf');
  };
  
  
  

  return (
    <div className="table-container">
      <h2 className="table-title" style={{ textAlign: 'right' }}>مجموع البطاقات التقنية</h2>
     <div style={{
      marginBottom: '20px',
      justifyContent: 'center',
    alignItems: 'center',
    gap: '10px'
}}>

    <label htmlFor="dateFilter" style={{
        fontWeight: 'bold',
        fontSize: '1rem', // Ajuster pour différentes tailles d'écran

    }}>
         التاريخ :
    </label>
    <input
        id="dateFilter"
        type="date"
        value={filterDate}
        onChange={(e) => setFilterDate(e.target.value)}
        style={{
            padding: '5px 10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '0.9rem', // Ajuster pour différentes tailles d'écran
            color: '#333'
        }}
    />
</div>

      <div className="form-group">
        <table>
          <thead>
            <tr>
              <th>اسم المؤسسة</th>
              <th> القسم</th>
              <th>مجال التدخل</th>
              <th>نوع النشاط</th>
              <th>عدد المستفيدين</th>
              <th>التاريخ</th>
              <th>ملاحظات</th>
              <th>تصرف</th>
            </tr>
          </thead>
          <tbody>
            {ficheTechniques && ficheTechniques.length > 0 ? (
              ficheTechniques.map((fiche, index) => (
                <tr key={index}>
                  <td>{fiche.institution}</td>
                  <td>{fiche.section}</td>
                  <td>{fiche.interventionField}</td>
                  <td>{fiche.customActivities && fiche.customActivities.length > 0 ? fiche.customActivities.map(activity => activity.name).join(', ') : 'Aucune activité'}</td>
                  <td>{fiche.beneficiariesCount}</td>
                  <td>{new Date(fiche.date).toLocaleDateString()}</td>
                  <td>{fiche.additionalComments}</td>
                  <td>
                    <button className="btn-edit" onClick={() => handleEdit(fiche.id)}>
                      تعديل
                    </button>
                    <br />
                    <br />
                    <button className="btn-delete" onClick={() => handleDelete(fiche.id)}>
                      حذف
                    </button>
                    <br />
                    <br />
                    <button className="btn-pdf" onClick={() => handleExportPDF(fiche)}>
                      تصدير PDF
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">Aucune fiche technique disponible</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {selectedFiche && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modifier Fiche Technique</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formInstitution">
                <Form.Label style={{ display: 'block', textAlign: 'right' }}> : اسم المؤسسة</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedFiche.institution || ''}
                  onChange={(e) => setSelectedFiche({ ...selectedFiche, institution: e.target.value })}
                  style={{ display: 'block', textAlign: 'right' }}
                >
                  {institutions.map((institution) => (
                    <option key={institution.id} value={institution.name}>
                      {institution.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formSection">
                <Form.Label style={{ display: 'block', textAlign: 'right' }}> : القسم</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedFiche.section || ''}
                  onChange={(e) => setSelectedFiche({ ...selectedFiche, section: e.target.value })}
                  style={{ display: 'block', textAlign: 'right' }}
                  dir="rtl" 
                />
              </Form.Group>
              <Form.Group controlId="formInterventionField">
  <Form.Label style={{ display: 'block', textAlign: 'right' }}> : مجال التدخل</Form.Label>
  <Form.Control
    as="select"
    value={selectedFiche.interventionField || ''}
    onChange={(e) => setSelectedFiche({ ...selectedFiche, interventionField: e.target.value })}
    style={{ display: 'block', textAlign: 'right' }}
  >
    {interventionFields.map((field, index) => (
      <option key={index} value={field}>
        {field}
      </option>
    ))}
  </Form.Control>
</Form.Group>

{selectedFiche.interventionField && (
  <Form.Group controlId="formCustomActivities">
    <Form.Label style={{ display: 'block', textAlign: 'right' }} > : نوع النشاط</Form.Label>
    
    {(selectedFiche.interventionField === 'تقديم خدمة المواكبة النفسية و الاجتماعية' ||
      selectedFiche.interventionField === 'مجالات أخرى') ? (
      
        <Form.Control
  type="text"
  value={selectedFiche.customActivities.map(activity => activity.name).join(', ')}
  onChange={(e) => {
    // Utilise une expression régulière pour séparer par des virgules et/ou des espaces
    const newActivities = e.target.value.split(/\s*,\s*/).filter(name => name.trim() !== ''); // Enlève les espaces inutiles et les valeurs vides
    setSelectedFiche(prevFiche => ({
      ...prevFiche,
      customActivities: newActivities.map(name => ({ name })), // Convertir chaque activité en objet
    }));
  }}
  placeholder="أدخل النشاط هنا"
  style={{ textAlign: 'right' }}
  dir="rtl"
/>

      
      
    ) : (
      
      activityFields[selectedFiche.interventionField]?.map((activity, index) => (
        <Form.Check
          key={index}
          type="checkbox"
          label={activity}
          checked={selectedActivities.includes(activity)}
          onChange={() => handleActivityChange(activity)}
          style={{ 
            display: 'block', 
            alignItems: 'center', 
            textAlign: 'right', 
            direction: 'rtl',
            gap: '40px' 
          }}
          dir="rtl" 
        />
      ))
      
    )}
  </Form.Group>
)}



              <Form.Group controlId="formBeneficiariesCount">
                <Form.Label style={{ display: 'block', textAlign: 'right' }}> : عدد المستفيدين</Form.Label>
                <Form.Control
                  type="number"
                  value={selectedFiche.beneficiariesCount || ''}
                  onChange={(e) => setSelectedFiche({ ...selectedFiche, beneficiariesCount: e.target.value })}
                  style={{ display: 'block', textAlign: 'right' }}
                  dir="rtl" 

                />
              </Form.Group>
              <Form.Group controlId="formAdditionalComments">
                <Form.Label style={{ display: 'block', textAlign: 'right' }}> : ملاحظات</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={selectedFiche.additionalComments || ''}
                  onChange={(e) => setSelectedFiche({ ...selectedFiche, additionalComments: e.target.value })}
                  style={{ display: 'block', textAlign: 'right' }}
                  dir="rtl" 

                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              إلغاء
            </Button>
            <Button variant="primary" onClick={handleSave}>
              حفظ
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AllFichesTechniques;
