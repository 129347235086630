import React, { useState, useEffect } from "react";
import "../../StylesCSS/OriginalInstitution.css";
import axios from "axios";
import Modal from "react-modal";

import { useUser } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../dashboardContent/config";

const OriginalInstitution = ({ setActiveSection }) => {
  const { userId } = useUser();
  console.log("userid======>", userId);
  const [selectedInstitution1, setSelectedInstitution1] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccessModal1, setShowSuccessModal1] = useState(false);

  const [institutions, setInstitutions] = useState([]);
  const [institutionss, setInstitutionss] = useState([]);

  const [editFormData, setEditFormData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false); // Modal pour la modification

  const [receptionInstitution, setReceptionInstitution] = useState([""]);
  const [loading, setLoading] = useState(true);
  const [showFormModal, setShowFormModal] = useState(false); // Modal pour le formulaire d'ajout
  const [
    selectedReceptionEtablissementId,
    setSelectedReceptionEtablissementId,
  ] = useState(null); // ID de l'établissement sélectionné pour la suppression
  const [
    selectedEtablissementToModif,
    setSelectedEtablissementToModif,
  ] = useState({}); // Établissement sélectionné pour la modification

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false); // Modal de confirmation de suppression

  const handleReceptionInstitutionChange = (index, value) => {
    console.log(`receptionInstitution ${index + 1}:`, value);
    const updatedReceptionInstitution = [...receptionInstitution];
    updatedReceptionInstitution[index] = value;
    setReceptionInstitution(updatedReceptionInstitution);
  };

  const handleAddReceptionInstitution = () => {
    setReceptionInstitution([...receptionInstitution, ""]);
  };

  const handleRemoveReceptionInstitution = (index) => {
    const updatedReceptionInstitution = receptionInstitution.filter(
      (_, i) => i !== index
    );
    setReceptionInstitution(updatedReceptionInstitution);
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/originalinstitution/user/${userId}`)
      .then((response) => {
        console.log("my data", response.data); // Vérifiez le format des données ici
        setInstitutionss(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching institutionss:", error);
        setLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    console.log("selectedEtablissementToModif:", selectedEtablissementToModif); // Vérifiez l'objet complet
    if (selectedEtablissementToModif.dateReunionConseil) {
      console.log(
        "selec modif date",
        selectedEtablissementToModif.dateReunionConseil
      );
      setSelectedEtablissementToModif((prev) => ({
        ...prev,
        Date_reunion_Conseil: new Date(
          selectedEtablissementToModif.dateReunionConseil
        )
          .toISOString()
          .split("T")[0],
      }));
    }
  }, [selectedEtablissementToModif.Date_reunion_Conseil]);

  const [formData, setFormData] = useState({
    academie: "",
    direction: "",
    service: "",
    typeInstitution: "",
    institution: "",
    institutionCode: "",
    town: "",
    environment: "",
    Date_reunion_Conseil: "",
    level: "",
    section: "",
    academicYear: "",
    femaleRegistered: "",
    femaleRemoved: "",
    femalePresent: "",
    maleRegistered: "",
    maleRemoved: "",
    malePresent: "",
  });

  const handleOpenModal = () => {
    setFormData({
      academie: "",
      direction: "",
      service: "",
      typeInstitution: "",
      institution: "",
      institutionCode: "",
      town: "",
      environment: "",
      Date_reunion_Conseil: "",
      level: "",
      section: "",
      academicYear: "",
      femaleRegistered: "",
      femaleRemoved: "",
      femalePresent: "",
      maleRegistered: "",
      maleRemoved: "",
      malePresent: "",
    });
    setReceptionInstitution([""]);
    setShowFormModal(true);
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/excel/institutions/${userId}`)
      .then((response) => setInstitutions(response.data))
      .catch((error) =>
        console.error("Erreur lors de la récupération des institutions:", error)
      );
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Transformer les institutions réceptrices en une liste de chaînes
    const receptionInstitutionNames = receptionInstitution.map(
      (institution) => institution
    );

    // Préparer les données dans le format attendu par le backend
    const dataToSubmit = {
      userId: userId, // Utiliser directement l'ID utilisateur
      academie: formData.academie,
      direction: formData.direction,
      service: formData.service,
      typeInstitution: formData.typeInstitution,
      institution: formData.institution,
      institutionCode: formData.institutionCode,
      town: formData.town,
      environment: formData.environment,
      dateReunionConseil: formData.Date_reunion_Conseil, // Correspondance avec la clé backend
      level: formData.level,
      section: formData.section,
      academicYear: formData.academicYear,
      femaleRegistered: parseInt(formData.femaleRegistered), // Convertir en nombre
      femaleRemoved: parseInt(formData.femaleRemoved),
      femalePresent: parseInt(formData.femalePresent),
      maleRegistered: parseInt(formData.maleRegistered),
      maleRemoved: parseInt(formData.maleRemoved),
      malePresent: parseInt(formData.malePresent),
      receptionInstitutions: receptionInstitutionNames, // Juste une liste de noms
    };

    console.log("Données envoyées au backend :", dataToSubmit);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/originalinstitution/submit`,
        dataToSubmit
      );
      console.log("Formulaire soumis avec succès :", response.data);
      setIsPopupVisible(true);
      setTimeout(() => {
        setIsPopupVisible(false);
      }, 3000);
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    }
  };

  const handleEdit = (institution) => {
    setSelectedReceptionEtablissementId(institution.id);
    console.log("id institution --->", institution.id);
    setSelectedEtablissementToModif({
      ...institution,
      dateReunionConseil: institution.dateReunionConseil
        ? new Date(institution.dateReunionConseil).toISOString().split("T")[0]
        : "", // Assurer que la date est au format YYYY-MM-DD
    });
    setShowEditModal(true);
  };

  const handleEditSubmit = (event) => {
    console.log("data to modif",selectedEtablissementToModif);
    event.preventDefault();
    fetch(`${API_BASE_URL}/api/originalinstitution/update/${selectedReceptionEtablissementId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(selectedEtablissementToModif),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "Erreur de mise à jour");
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log("Données mises à jour reçues de l'API:", data);
        const updatedEtablissements = institutionss.map((inst) =>
          inst.id === selectedReceptionEtablissementId ? data : inst
        );
        setInstitutionss(updatedEtablissements);
        setShowEditModal(false);
        setShowSuccessModal1(true)
      })
      .catch((error) => {
        console.error("Erreur:", error);
        alert(error.message); // Affichage de l'erreur dans l'UI
      });    
  };
  const closeSuccessModal1 = () => {
    setShowSuccessModal1(false);
  };

  const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };
  console.log("inssss", institutionss);

  const confirmDelete = () => {
    fetch(
      `${API_BASE_URL}/api/originalinstitution/delete/${selectedReceptionEtablissementId}`,
      {
        method: "DELETE",
      }
    ).then(() => {
      // Filtrer l'établissement supprimé dans le tableau institutions
      setInstitutionss((prevInstitutions) =>
        prevInstitutions.filter(
          (inst) => inst.id !== selectedReceptionEtablissementId
        )
      );
      setShowConfirmDeleteModal(false); // Fermer le modal de confirmation après suppression
      setShowSuccessModal(true);
    });
  };
  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target; // Récupère le name et la valeur de l'input
    setSelectedEtablissementToModif((prev) => ({
      ...prev,
      [name]: value, // Utilise la bonne propriété
    }));
  };

  const handleDelete = (id) => {
    console.log("id===========>", id);
    setSelectedReceptionEtablissementId(id); // Définir l'ID de l'établissement à supprimer
    setShowConfirmDeleteModal(true); // Afficher le modal de confirmation
  };

  return (
    <div>
      <div style={{ maxWidth: "800px", margin: "auto" }}>
        <h2 style={{ textAlign: "right" }}> : المؤسسات الأصلية</h2>
        <div className="buttoncontainer">
          <button
            onClick={() => handleGoBack("PageLayout2")}
            className="backbtn"
          >
            <FontAwesomeIcon icon={faArrowLeft} /> رجوع
          </button>
          <button className="add-button1" onClick={handleOpenModal}>
            إضافة مؤسسة جديدة
          </button>
        </div>
        <table
          border="1"
          style={{ width: "100%", textAlign: "left", marginRight: "100px" }}
        >
          <thead>
            <tr>
              <th>تصرف</th> {/* Colonne pour les actions */}
              <th>الاكاديمية الجهوية</th>
              <th>المديرية الاقليمية</th>
              {/*  <th>المصلحة</th>
        <th>نوع الموسسة</th>
        <th>رمز الموسسة</th>
        <th>البلدة</th>
        <th>الوسط</th> */}
              <th>تاريخ انعقاد المجلس</th>
              <th>السنة الدراسية</th>
              <th>المؤسسات المستقبلة</th>
              <th>المؤسسة الأصلية</th>
            </tr>
          </thead>
          <tbody>
            {institutionss.map((inst) => (
              <tr key={inst.institutionCode}>
                <td>
                  <div className="button-container1">
                    <button
                      className="edit-button"
                      onClick={() => handleEdit(inst)}
                    >
                      Modifier
                    </button>
                    {inst.id ? (
                      <button
                        className="delete-button"
                        onClick={() => {
                          console.log("inst moha:", inst); // Ajoutez cette ligne pour inspecter l'objet
                          handleDelete(inst.id);
                        }}
                      >
                        Supprimer
                      </button>
                    ) : (
                      <span>Id manquant</span>
                    )}
                  </div>
                </td>
                <td>{inst.academie}</td>
                <td>{inst.direction}</td>
                {/*  <td>{inst.service}</td>
          <td>{inst.typeInstitution}</td>
          <td>{inst.institutionCode}</td>
          <td>{inst.town}</td>
          <td>{inst.environment}</td> */}
                <td>{inst.dateReunionConseil}</td>
                <td>{inst.academicYear}</td>
                <td>
                  {inst.receptionInstitutions &&
                  Array.isArray(inst.receptionInstitutions) &&
                  inst.receptionInstitutions.length > 0
                    ? inst.receptionInstitutions.map((reception, index) => (
                        <div key={index}>{reception} -</div>
                      ))
                    : "Aucune institution"}
                </td>

                <td>{inst.institution}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          isOpen={showFormModal}
          onRequestClose={() => setShowFormModal(false)}
          contentLabel="Ajouter un établissement"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "10px",
              overflowY: "auto",
              maxHeight: "80vh",
              maxWidth: "600px",
              width: "100%",
            },
          }}
        >
          <div style={{ maxWidth: "600px", margin: "auto" }}>
            {isPopupVisible ? (
              <div style={{ textAlign: "center" }}>
                <h3>Les données ont été enregistrées avec succès !</h3>
                <button
                  onClick={() => {
                    setIsPopupVisible(false); // Cacher le message
                    setShowFormModal(false); // Fermer le modal
                  }}
                  className="button"
                  style={{
                    marginTop: "15px",
                    padding: "10px 20px",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Fermer
                </button>
              </div>
            ) : (
              <div>
                <h2 style={{ textAlign: "right" }}>إضافة مؤسسة أصلية جديدة</h2>
                <div style={{ maxWidth: "600px", margin: "auto" }}>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div className="custom-form">
                        <div className="form-group-left">
                          <div className="input-group">
                            <label htmlFor="town">البلدة</label>
                            <input
                              type="text"
                              id="town"
                              name="town"
                              value={formData.town}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="environment">الوسط</label>
                            <select
                              id="environment"
                              name="environment"
                              value={formData.environment}
                              onChange={handleChange}
                              dir="rtl"
                            >
                              <option value="">اختر الوسط</option>
                              <option value="حضري">حضري</option>
                              <option value="قروي">قروي</option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label htmlFor="level">المستوي</label>
                            <input
                              type="text"
                              id="level"
                              name="level"
                              value={formData.level}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="section">الشعبة</label>
                            <input
                              type="text"
                              id="section"
                              name="section"
                              value={formData.section}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="academicYear">السنة الدراسية</label>
                            <input
                              type="text"
                              id="academicYear"
                              name="academicYear"
                              value={formData.academicYear}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="Date_reunion_Conseil">
                              {" "}
                              تاريخ انعقاد المجلس
                            </label>
                            <input
                              type="date"
                              id="Date_reunion_Conseil"
                              name="Date_reunion_Conseil"
                              value={formData.Date_reunion_Conseil}
                              onChange={handleChange}
                              dir="rtl"
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group-right">
                          <div className="input-group">
                            <label htmlFor="academie">الاكاديمية الجهوية</label>
                            <input
                              type="text"
                              id="academie"
                              name="academie"
                              value={formData.academie}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="direction">
                              المديرية الاقليمية
                            </label>
                            <input
                              type="text"
                              id="direction"
                              name="direction"
                              value={formData.direction}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="service">المصلحة</label>
                            <input
                              type="text"
                              id="service"
                              name="service"
                              value={formData.service}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="typeInstitution">نوع الموسسة</label>
                            <select
                              id="typeInstitution"
                              name="typeInstitution"
                              value={formData.typeInstitution}
                              onChange={handleChange}
                              dir="rtl"
                            >
                              <option value="">اختر</option>
                              <option value="donnee1"> عام </option>
                              <option value="donnee2"> خاص </option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label htmlFor="original-school">: المؤسسة</label>
                            <select
                              className="select"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  institution: e.target.value,
                                })
                              }
                              value={formData.institution} // Lier la valeur à formData.institution
                            >
                              <option value="" style={{ textAlign: "right" }}>
                                -- اختر --
                              </option>
                              {institutions &&
                                institutions.length > 0 &&
                                institutions.map((institution) => (
                                  <option
                                    style={{ textAlign: "right" }}
                                    key={institution}
                                    value={institution}
                                  >
                                    {institution}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="input-group">
                            <label htmlFor="institutionCode">رمز الموسسة</label>
                            <input
                              type="text"
                              id="institutionCode"
                              name="institutionCode"
                              value={formData.institutionCode}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group"
                        style={{ marginBottom: "15px" }}
                      >
                        <label style={{ display: "block", textAlign: "right" }}>
                          {" "}
                          : المؤسسات المستقبلة
                        </label>
                        {receptionInstitution.map(
                          (receptionInstitutio, index) => (
                            <div
                              key={index}
                              style={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                value={receptionInstitutio}
                                onChange={(e) =>
                                  handleReceptionInstitutionChange(
                                    index,
                                    e.target.value
                                  )
                                }
                                placeholder={`المؤسسة المستقبلة ${index + 1}`}
                                required
                                style={{
                                  flex: 1,
                                  marginRight: "10px",
                                  textAlign: "right",
                                }}
                                dir="rtl"
                              />
                              {/* Affiche le bouton "+" uniquement pour le dernier élément */}
                              {index === receptionInstitution.length - 1 && (
                                <button
                                  type="button"
                                  onClick={handleAddReceptionInstitution}
                                  className="btn btn-secondary"
                                  style={{ marginRight: "5px" }}
                                >
                                  +
                                </button>
                              )}
                              {/* Affiche le bouton "-" uniquement si la longueur de receptionInstitution > 1 */}
                              {receptionInstitution.length > 1 && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveReceptionInstitution(index)
                                  }
                                  className="btn btn-danger"
                                >
                                  -
                                </button>
                              )}
                            </div>
                          )
                        )}
                      </div>

                      <div className="centered-form">
                        <div className="form-group-left">
                          <div className="input-group">
                            <label>إناث</label>
                            <input
                              type="number"
                              name="femaleRegistered"
                              value={formData.femaleRegistered}
                              onChange={handleChange}
                              placeholder="عدد المسجلون"
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="number"
                              name="femaleRemoved"
                              value={formData.femaleRemoved}
                              onChange={handleChange}
                              placeholder="عدد المشطب عليهم"
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="number"
                              name="femalePresent"
                              value={formData.femalePresent}
                              onChange={handleChange}
                              placeholder="  عدد الحاضرون"
                              dir="rtl"
                            />
                          </div>
                        </div>
                        <div className="form-group-right">
                          <div className="input-group">
                            <label>ذكور</label>
                            <input
                              type="number"
                              name="maleRegistered"
                              value={formData.maleRegistered}
                              onChange={handleChange}
                              placeholder=" عدد المسجلون"
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="number"
                              name="maleRemoved"
                              value={formData.maleRemoved}
                              onChange={handleChange}
                              placeholder="عدد المشطب عليهم"
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="number"
                              name="malePresent"
                              value={formData.malePresent}
                              onChange={handleChange}
                              placeholder="  عدد الحاضرون"
                              dir="rtl"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="buttoncontainer">
                      <button type="submit" className="button submitbutton">
                        تأكيد
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </Modal>
        <Modal
          isOpen={showConfirmDeleteModal}
          onRequestClose={() => setShowConfirmDeleteModal(false)}
          contentLabel="Confirmation de suppression"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "10px",
            },
          }}
        >
          <h4 style={{ textAlign: "right" }}> : تأكيد الحذف</h4>
          <p>هل أنت متأكد أنك تريد حذف هذه المؤسسة؟</p>
          <button className="confirm-button" onClick={confirmDelete}>
            نعم
          </button>
          <button
            className="modalbutton"
            onClick={() => setShowConfirmDeleteModal(false)}
          >
            لا
          </button>
        </Modal>
        <Modal
        isOpen={showSuccessModal}
        onRequestClose={closeSuccessModal}
        contentLabel="Succès de suppression"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
          },
        }}
      >
        <h4 style={{ textAlign: "right" }}>لقد تم حدف المؤسسة بنجاح</h4>
        <button
          className="modalbutton"
          onClick={closeSuccessModal}
        >
          إغلاق
        </button>
      </Modal>
        <Modal
          isOpen={showEditModal}
          onRequestClose={() => setShowEditModal(false)}
          contentLabel="Ajouter un établissement"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "10px",
              overflowY: "auto",
              maxHeight: "80vh",
              maxWidth: "600px",
              width: "100%",
            },
          }}
        >
          <div style={{ maxWidth: "600px", margin: "auto" }}>
            {isPopupVisible ? (
              <div style={{ textAlign: "center" }}>
                <h3>Les données ont été enregistrées avec succès !</h3>
                <button
                  onClick={() => {
                    setIsPopupVisible(false); // Cacher le message
                    setShowFormModal(false); // Fermer le modal
                  }}
                  className="button"
                  style={{
                    marginTop: "15px",
                    padding: "10px 20px",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Fermer
                </button>
              </div>
            ) : (
              <div>
                <h2 style={{ textAlign: "right" }}> تعديل مؤسسة أصلية </h2>
                <div style={{ maxWidth: "600px", margin: "auto" }}>
                  <form onSubmit={handleEditSubmit}>
                    <div>
                      <div className="custom-form">
                        <div className="form-group-left">
                          <div className="input-group">
                            <label htmlFor="town">البلدة</label>
                            <input
                              type="text"
                              id="town"
                              name="town"
                              value={selectedEtablissementToModif.town || ""} // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="environment">الوسط</label>
                            <select
                              id="environment"
                              name="environment"
                              value={
                                selectedEtablissementToModif.environment || ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              dir="rtl"
                            >
                              <option value="">اختر الوسط</option>
                              <option value="حضري">حضري</option>
                              <option value="قروي">قروي</option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label htmlFor="level">المستوي</label>
                            <input
                              type="text"
                              id="level"
                              name="level"
                              value={selectedEtablissementToModif.level || ""} // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="section">الشعبة</label>
                            <input
                              type="text"
                              id="section"
                              name="section"
                              value={selectedEtablissementToModif.section || ""} // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="academicYear">السنة الدراسية</label>
                            <input
                              type="text"
                              id="academicYear"
                              name="academicYear"
                              value={selectedEtablissementToModif.academicYear || ""}
                              onChange={handleInputChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="dateReunionConseil">
                              تاريخ انعقاد المجلس
                            </label>
                            <input
                              type="date"
                              id="dateReunionConseil"
                              name="dateReunionConseil"
                              value={
                                selectedEtablissementToModif.dateReunionConseil ||
                                ""
                              }
                              onChange={handleInputChange}
                              dir="rtl"
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group-right">
                          <div className="input-group">
                            <label htmlFor="academie">الاكاديمية الجهوية</label>
                            <input
                              type="text"
                              id="academie"
                              name="academie"
                              value={
                                selectedEtablissementToModif.academie || ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="direction">
                              المديرية الاقليمية
                            </label>
                            <input
                              type="text"
                              id="direction"
                              name="direction"
                              value={
                                selectedEtablissementToModif.direction || ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="service">المصلحة</label>
                            <input
                              type="text"
                              id="service"
                              name="service"
                              value={selectedEtablissementToModif.service || ""} // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="typeInstitution">نوع الموسسة</label>
                            <select
                              id="typeInstitution"
                              name="typeInstitution"
                              value={
                                selectedEtablissementToModif.typeInstitution ||
                                ""
                              }
                              onChange={handleInputChange}
                              dir="rtl"
                            >
                              <option value="">اختر</option>
                              <option value="donnee1"> عام </option>
                              <option value="donnee2"> خاص </option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label htmlFor="original-school">: المؤسسة</label>
                            <select
                              className="select"
                              value={
                                selectedEtablissementToModif.institution || ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                            >
                              <option value="" style={{ textAlign: "right" }}>
                                -- اختر --
                              </option>
                              {institutions &&
                                institutions.length > 0 &&
                                institutions.map((institution) => (
                                  <option
                                    style={{ textAlign: "right" }}
                                    key={institution}
                                    value={institution}
                                  >
                                    {institution}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="input-group">
                            <label htmlFor="institutionCode">رمز الموسسة</label>
                            <input
                              type="text"
                              id="institutionCode"
                              name="institutionCode"
                              value={
                                selectedEtablissementToModif.institutionCode ||
                                ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              dir="rtl"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group"
                        style={{ marginBottom: "15px" }}
                      >
                        <label style={{ display: "block", textAlign: "right" }}>
                          : المؤسسات المستقبلة
                        </label>
                        {(
                          selectedEtablissementToModif.receptionInstitutions ||
                          []
                        ).map((receptionInstitution, index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={receptionInstitution}
                              onChange={(e) => {
                                const updatedInstitutions = [
                                  ...selectedEtablissementToModif.receptionInstitutions,
                                ];
                                updatedInstitutions[index] = e.target.value; // Met à jour la valeur à l'index donné
                                setSelectedEtablissementToModif({
                                  ...selectedEtablissementToModif,
                                  receptionInstitutions: updatedInstitutions,
                                });
                              }}
                              placeholder={`المؤسسة المستقبلة ${index + 1}`}
                              required
                              style={{
                                flex: 1,
                                marginRight: "10px",
                                textAlign: "right",
                              }}
                              dir="rtl"
                            />
                            {/* Bouton "+" pour ajouter un nouvel élément */}
                            {index ===
                              (
                                selectedEtablissementToModif.receptionInstitutions ||
                                []
                              ).length -
                                1 && (
                              <button
                                type="button"
                                onClick={() => {
                                  const updatedInstitutions = [
                                    ...selectedEtablissementToModif.receptionInstitutions,
                                    "",
                                  ]; // Ajoute un nouvel élément vide
                                  setSelectedEtablissementToModif({
                                    ...selectedEtablissementToModif,
                                    receptionInstitutions: updatedInstitutions,
                                  });
                                }}
                                className="btn btn-secondary"
                                style={{ marginRight: "5px" }}
                              >
                                +
                              </button>
                            )}
                            {/* Bouton "-" pour supprimer un élément */}
                            {(
                              selectedEtablissementToModif.receptionInstitutions ||
                              []
                            ).length > 1 && (
                              <button
                                type="button"
                                onClick={() => {
                                  const updatedInstitutions = selectedEtablissementToModif.receptionInstitutions.filter(
                                    (_, i) => i !== index
                                  ); // Supprime l'élément à l'index donné
                                  setSelectedEtablissementToModif({
                                    ...selectedEtablissementToModif,
                                    receptionInstitutions: updatedInstitutions,
                                  });
                                }}
                                className="btn btn-danger"
                              >
                                -
                              </button>
                            )}
                          </div>
                        ))}
                      </div>

                      <div className="centered-form">
                        <div className="form-group-left">
                          <div className="input-group">
                            <label>إناث</label>
                            <input
                              type="number"
                              name="femaleRegistered"
                              value={
                                selectedEtablissementToModif.femaleRegistered ||
                                ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              placeholder="عدد المسجلون"
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="number"
                              name="femaleRemoved"
                              value={
                                selectedEtablissementToModif.femaleRemoved || ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              placeholder="عدد المشطب عليهم"
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="number"
                              name="femalePresent"
                              value={
                                selectedEtablissementToModif.femalePresent || ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              placeholder="  عدد الحاضرون"
                              dir="rtl"
                            />
                          </div>
                        </div>
                        <div className="form-group-right">
                          <div className="input-group">
                            <label>ذكور</label>
                            <input
                              type="number"
                              name="maleRegistered"
                              value={
                                selectedEtablissementToModif.maleRegistered ||
                                ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              placeholder=" عدد المسجلون"
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="number"
                              name="maleRemoved"
                              value={
                                selectedEtablissementToModif.maleRemoved || ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              placeholder="عدد المشطب عليهم"
                              dir="rtl"
                            />
                          </div>
                          <div className="input-group">
                            <input
                              type="number"
                              name="malePresent"
                              value={
                                selectedEtablissementToModif.malePresent || ""
                              } // Utiliser la valeur de selectedEtablissementToModif
                              onChange={handleInputChange}
                              placeholder="  عدد الحاضرون"
                              dir="rtl"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buttoncontainer">
                      <button
                        className="submitbutton"
                        type="submit"
                        style={{ flex: 1, marginLeft: "10px" }}
                      >
                        تعديل
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </Modal>
        <Modal
        isOpen={showSuccessModal1}
        onRequestClose={closeSuccessModal1}
        contentLabel="Succès de modification"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            overflowY: 'auto',
            maxHeight: '80vh',
            maxWidth: '600px',
            width: '100%',
          },
        }}
      >
        <h4 style={{ textAlign: 'right' }}>لقد تم تعديل المعلومات بنجاح</h4>
        <button className="modalbutton" onClick={closeSuccessModal1}>
          إغلاق
        </button>
      </Modal>
      </div>
    </div>
  );
};

export default OriginalInstitution;
