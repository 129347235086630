import React, { useState , useEffect } from 'react';
import { MenuItem, FormControl, Select, InputLabel, Box, Typography, Button } from '@mui/material';
import Modal from "react-modal";
import { useUser } from '../../context/UserContext';
import axios from 'axios';
import { API_BASE_URL } from '../dashboardContent/config'; 


const DeuxiemeLangueSelonDepartement = ({ setActiveSection })  => {


  const { userId } = useUser(); 
  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // État pour contrôler l'ouverture du modal


  const [language, setLanguage] = useState('');
  console.log("institution ->",selectedInstitution);
  console.log("classe ->", selectedClass);
  console.log("langue ->",language);




  useEffect(() => {
    axios.get(`${API_BASE_URL}/api/excel/institutions/${userId}`)
        .then(response => setInstitutions(response.data))
        .catch(error => console.error('Erreur lors de la récupération des institutions:', error));
}, [userId]);

// Récupérer les classes quand une institution est sélectionnée
useEffect(() => {
    if (selectedInstitution) {
        axios.get(`${API_BASE_URL}/api/excel/classes/${userId}/${selectedInstitution}`)
            .then(response => setClasses(response.data))
            .catch(error => console.error('Erreur lors de la récupération des classes:', error));
    }
}, [selectedInstitution, userId]);


  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSave = async () => {
    const dataToSave = {
        user: { id: userId }, // Assurez-vous d'envoyer l'objet utilisateur avec son id
        institution: selectedInstitution,
        classe: selectedClass,
        langue: language,
    };

    // Sauvegarde des données
    try {
        const saveResponse = await axios.post(`${API_BASE_URL}/api/deuxieme-langue/save`, dataToSave);
        console.log('Données sauvegardées:', saveResponse.data);
        setIsSuccessModalOpen(true);

    } catch (error) {
        console.error('Erreur lors de la sauvegarde des données:', error);
    }

    // Mise à jour de la langue de l'étudiant avec une requête PUT
   // Construisez l'URL avec les paramètres requis
   const url = `${API_BASE_URL}/api/students/update-language?userId=${userId}&classe=${selectedClass}&institution=${encodeURIComponent(selectedInstitution)}&newLanguage=${language}`;

   console.log("URL de mise à jour:", url);

   try {
       const updateResponse = await axios.put(url);
       console.log('Données mises à jour:', updateResponse.data);
   } catch (error) {
       console.error('Erreur lors de la mise à jour de la langue:', error.response ? error.response.data : error);
   }
};

const closeSuccessModal = () => {
  setIsSuccessModalOpen(false); 
};

const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: 2, 
        padding: '2rem', 
        backgroundColor: '#f4f6f8', // Couleur de fond douce
        borderRadius: '10px', 
        border: '1px solid #dcdcdc',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        margin: 'auto',
        textAlign: 'right',
      }}
    >
      <Typography 
        variant="h5" 
        sx={{ 
          marginBottom: '1rem', 
          fontWeight: '500', 
          color: '#2c3e50',
          textAlign: 'center'
        }}
      >
         اختيار اللغة الأجنبية الثانية حسب القسم
      </Typography>

      <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem', width: '300px' }}>
        <InputLabel sx={{ textAlign: 'right', fontSize: '1.1rem', marginBottom: '0.5rem', color: '#2c3e50' }}>
        اختر المؤسسة        
        </InputLabel>
        <Select
          className="select" 
          onChange={(e) => setSelectedInstitution(e.target.value)} 
          value={selectedInstitution}
          label="اختر المؤسسة"
          sx={{ 
            textAlign: 'right', 
            direction: 'rtl',
            backgroundColor: '#ffffff', // Fond du Select
            '&:focus': {
              borderColor: '#2980b9', // Couleur du focus
            },
          }}
        >
          {institutions && institutions.length > 0 && institutions.map(institution => (
                    <MenuItem style={{ textAlign: 'right' }}  key={institution} value={institution}>{institution}</MenuItem>
                ))}
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem', width: '300px' }}>

      {selectedInstitution && (
                <>
                    <InputLabel sx={{ textAlign: 'right', fontSize: '1rem', marginBottom: '0.5rem', color: '#2c3e50' }}>
                    رقم القسم
                   </InputLabel>
                   <Select 
                   className="select" onChange={(e) => setSelectedClass(e.target.value)} value={selectedClass}
          label="رقم القسم"
          sx={{ 
            textAlign: 'right', 
            direction: 'rtl',
            backgroundColor: '#ffffff', // Fond du Select
            '&:focus': {
              borderColor: '#2980b9', // Couleur du focus
            },
          }}
        >
                        {classes && classes.length > 0 && classes.map(classe => (
                            <MenuItem style={{ textAlign: 'right' }}  key={classe} value={classe}>{classe}</MenuItem>
                        ))}
        </Select>

                   
                </>
            )}
      </FormControl>

      {/* رقم القسم */}
      {/* <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem', width: '300px' }}>
        <InputLabel sx={{ textAlign: 'right', fontSize: '1rem', marginBottom: '0.5rem', color: '#2c3e50' }}>
          رقم القسم
        </InputLabel>
        <Select
          value={department}
          onChange={handleDepartmentChange}
          label="رقم القسم"
          sx={{ 
            textAlign: 'right', 
            direction: 'rtl',
            backgroundColor: '#ffffff', // Fond du Select
            '&:focus': {
              borderColor: '#2980b9', // Couleur du focus
            },
          }}
        >
          <MenuItem value={1}>قسم 1</MenuItem>
          <MenuItem value={2}>قسم 2</MenuItem>
          <MenuItem value={3}>قسم 3</MenuItem>
        </Select>
      </FormControl> */}

      {/* اللغة الأجنبية الثانية */}
      <FormControl fullWidth variant="outlined" sx={{ width: '300px' }}>
        <InputLabel sx={{ textAlign: 'right', fontSize: '1rem', marginBottom: '0.5rem', color: '#2c3e50' }}>
          اللغة الأجنبية الثانية
        </InputLabel>
        <Select
          value={language}
          onChange={handleLanguageChange}
          label="اللغة الأجنبية الثانية"
          sx={{ 
            textAlign: 'right', 
            direction: 'rtl',
            backgroundColor: '#ffffff', // Fond du Select
            '&:focus': {
              borderColor: '#2980b9', // Couleur du focus
            },
          }}
        >
          <MenuItem value="اللغة الألمانية">اللغة الألمانية</MenuItem>
          <MenuItem value="اللغة الإنجليزية">اللغة الإنجليزية</MenuItem>
          <MenuItem value="اللغة الإسبانية">اللغة الإسبانية</MenuItem>
        </Select>
      </FormControl>

      {/* Boutons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
       
        <Button 
          variant="outlined" 
          color="secondary" 
          onClick={() => handleGoBack('PageLayout2')}
          sx={{ 
            borderRadius: '8px', 
            borderColor: '#e74c3c', // Couleur de la bordure rouge
            color: '#e74c3c', // Couleur du texte
            '&:hover': {
              backgroundColor: '#f8d7da', // Couleur au survol
            }
          }}
        >
          رجوع
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSave} 
          sx={{ 
            borderRadius: '8px', 
            backgroundColor: '#27ae60', // Vert doux
            '&:hover': {
              backgroundColor: '#219150', // Couleur au survol
            }
          }}
        >
          تاكيد
        </Button>
        {/* Modal de succès */}
      <Modal
        isOpen={isSuccessModalOpen}
        onRequestClose={closeSuccessModal}
        contentLabel="Succès de modification"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            overflowY: 'auto',
            maxHeight: '80vh',
            maxWidth: '600px',
            width: '100%',
          },
        }}
      >
        <h4 style={{ textAlign: 'right' }}>تم حفظ البيانات بنجاح</h4>
        <button className="modalbutton" onClick={closeSuccessModal}>
          إغلاق
        </button>
      </Modal>
      </Box>
    </Box>
  );
};

export default DeuxiemeLangueSelonDepartement;
