import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { interventionFields } from '../../utils/interventionFields';
import { activityFields } from '../../utils/activityFields';
import jsPDF from 'jspdf'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'jspdf-autotable';
import { API_BASE_URL } from '../dashboardContent/config'; 



const FicheTechnique = ({ setActiveSection }) => {
  
  const { userId } = useUser();
  const [institutions, setInstitutions] = useState([]);
  const [section, setSection] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [selectedInterventionField, setSelectedInterventionField] = useState('');
  const [selectedActivityFields, setSelectedActivityFields] = useState([]);
  const [beneficiariesCount, setBeneficiariesCount] = useState('');
  const [error, setError] = useState('');
  const [date, setDate] = useState(''); // État pour la date
  const [customActivity, setCustomActivity] = useState(''); // État pour le champ personnalisé
  const [allActivities, setAllActivities] = useState([]); // État pour toutes les activités
  const [additionalComments, setAdditionalComments] = useState('');
  const selectedInstitutionObject = institutions.find(inst => inst.id === parseInt(selectedInstitution));

 

  useEffect(() => {
    if (userId) {
      const fetchInstitutions = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/api/institutions/user/${userId}`);
          const data = await response.json();
          
          console.log('Institutions récupérées:', data);
          setInstitutions(data);
        } catch (error) {
          console.error('Erreur lors de la récupération des institutions:', error);
        }
      };
      
      fetchInstitutions();
    }
  }, [userId]);

  useEffect(() => {
    setAllActivities(activityFields[selectedInterventionField] || []);
  }, [selectedInterventionField]);

  const handleInterventionChange = (e) => {
    const selectedField = e.target.value;
    setSelectedInterventionField(selectedField);
    setCustomActivity(''); // Réinitialiser le champ personnalisé si nécessaire
  };

  const handleActivityChange = (e) => {
    const activity = e.target.value;
    setSelectedActivityFields((prevFields) =>
      prevFields.includes(activity)
        ? prevFields.filter((item) => item !== activity)
        : [...prevFields, activity]
    );
  };

  const handleBeneficiariesCountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setBeneficiariesCount(value);
      setError('');
    } else {
      setError('Il faut saisir un nombre');
    }
  };

  const handleSave = async () => {
    // Trouver l'institution sélectionnée
    const selectedInstitutionObject = institutions.find(inst => inst.id === parseInt(selectedInstitution));

    if (!selectedInstitutionObject) {
        console.error('Institution non trouvée.');
        return;
    }
    const ficheTechnique = {
        institution: selectedInstitutionObject.name,  // Utiliser le nom de l'institution
        section : section,
        interventionField: selectedInterventionField,
        customActivities: selectedActivityFields.length === 0 && customActivity ? [{ name: customActivity, user: { id: userId } }] : selectedActivityFields.map(activity => ({ name: activity, user: { id: userId } })),
        beneficiariesCount: beneficiariesCount,
        date: date,
        additionalComments : additionalComments,
        user: { id: userId }
    };
    console.log("fiche technique ->: ", ficheTechnique);
    console.log("customActivities", ficheTechnique.customActivities);

    try {
        const response = await fetch(`${API_BASE_URL}/api/fiche-technique/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json ; charset=UTF-8',
            },
            body: JSON.stringify(ficheTechnique),
        });

        if (response.ok) {
            alert('Formulaire sauvegardé avec succès');
        } else {
            alert('Erreur lors de la sauvegarde');
        }
    } catch (error) {
        console.error('Erreur lors de la sauvegarde du formulaire:', error);
    }
};
const handleGoAllFicheTechniques = (section) => {
  setActiveSection(section);
  console.log("Retour");
};


const handleExportPDF = async () => {
  const doc = new jsPDF();

  // Ajouter la police arabe
  doc.addFileToVFS('NotoSansArabic-Regular2.ttf', '/fonts/NotoSansArabic-Regular2.ttf');
  doc.addFont('/fonts/NotoSansArabic-Regular2.ttf', 'NotoSansArabic', 'normal');

  // Ajouter une police pour le texte en français
  doc.addFileToVFS('NotoSans-Regular.ttf', '/fonts/NotoSans-Regular.ttf');
  doc.addFont('/fonts/NotoSans-Regular.ttf', 'NotoSans', 'normal');

  // Charger l'image
  const img = new Image();
  img.src = '/images/Photo1.PNG';
  await new Promise(resolve => {
    img.onload = resolve;
  });

  const imgWidth = 100;
  const imgHeight = (img.height * imgWidth) / img.width;
  doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);

  doc.setFont('NotoSansArabic');
  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes

  // Ajouter les trois premiers textes avec les nouvelles propriétés
  doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 15, { align: 'center' });
  doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 20, { align: 'center' });
  doc.text('     مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 25, { align: 'center' });

  // Réinitialiser les propriétés pour le texte suivant
  doc.setFontSize(20); // Taille de police par défaut pour le texte suivant
  doc.setTextColor(33, 140, 145); // Couleur par défaut pour le texte suivant
  doc.text('البطاقة التقنية', doc.internal.pageSize.width / 2, imgHeight + 38, { align: 'center' });
  // Formater les activités sélectionnées avec des tirets au début de chaque phrase
  const formattedActivities = selectedActivityFields.map(activity => ` ${activity}`).join('\n');

  const tableData = [
    [selectedInstitutionObject.name, 'اسم المؤسسة'],
    [section,'القسم'],
    [selectedInterventionField, 'مجال التدخل'],
    [beneficiariesCount, 'عدد المستفيدين'],
    [date, 'التاريخ'],
    [formattedActivities || customActivity, 'نوع النشاط'],
    [additionalComments,'ملاحظات']
  ];
  
  doc.autoTable({
    startY: imgHeight + 30,
    head: [['Field', 'Value']],
    body: tableData,
    theme: 'grid',
    styles: { fontSize: 12 },
    columnStyles: {
      0: {  cellWidth: 'auto', halign: 'right', font: 'NotoSansArabic' },  // Colonne 0 avec texte arabe
      1: { cellWidth: 50 ,halign: 'right', font: 'NotoSansArabic' }          // Colonne 1 avec texte français
    },
    headStyles: {
      fillColor: [33, 140, 145],
      font: 'NotoSansArabic',
      textColor: [255, 255, 255]
    },
    bodyStyles: {
      font: 'NotoSansArabic'
    }
  });

  const pageHeight = doc.internal.pageSize.height;
  const signatureY = pageHeight - 30; // Position Y pour les signatures

  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
  // Signature à droite
  doc.text(': توقيع اطار التوجيه التربوي', doc.internal.pageSize.width - 20, signatureY, { align: 'right' });

  // Signature à gauche
  doc.text(': توقيع مدير الموسسة', 20, signatureY, { align: 'left' });

  doc.save('fiche_technique.pdf');
};


  return (
    <div style={{ maxWidth: '600px', margin: 'auto' }}>
      <h2 style={{ textAlign: 'right' }}> البطاقة التقنية</h2>
      <div className="form-group" style={{ marginBottom: '15px' }}>
        <label htmlFor="institution" style={{ display: 'block', textAlign: 'right' }}> : اسم المؤسسة </label>
        <select
          id="institution"
          value={selectedInstitution}
          onChange={(e) => setSelectedInstitution(e.target.value)}
          className="form-control"
        >
          <option value="" style={{ textAlign: 'right' }}>اختر المؤسسة</option>
          {institutions.map((institution) => (
            <option key={institution.id} value={institution.id}>
              {institution.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="section" style={{ display: 'block', textAlign: 'right' }}> : القسم </label>
        <input
          type="text"
          id="section"
          value={section}
          onChange={(e) => setSection(e.target.value)}
          className="form-control"
          placeholder="أدخل القسم "
          style={{ display: 'block', textAlign: 'right' }}
          dir="rtl" 
        />
        {error && <small className="text-danger">{error}</small>}
      </div>
      <div className="form-group">
        <label htmlFor="interventionField" style={{ display: 'block', textAlign: 'right' }}> : مجال التدخل </label>
        <select
          id="interventionField"
          value={selectedInterventionField}
          onChange={handleInterventionChange}
          className="form-control"
        >
          <option value="" style={{ display: 'block', textAlign: 'right' }}>اختر مجال التدخل</option>
          {interventionFields.map((field, index) => (
            <option style={{ display: 'block', textAlign: 'right' }} key={index} value={field}>
              {field}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div className="form-group">
  <label style={{ display: 'block', textAlign: 'right' }}> : نوع النشاط</label>
  {selectedActivityFields.length === 0 &&
    (selectedInterventionField === 'تقديم خدمة المواكبة النفسية و الاجتماعية' ||
    selectedInterventionField === 'مجالات أخرى') ? (
    <input
      type="text"
      value={customActivity}
      onChange={(e) => setCustomActivity(e.target.value)}
      className="form-control"
      placeholder="أدخل النشاط هنا"
      style={{ display: 'block', textAlign: 'right' }}
      dir="rtl" 

    />
  ) : (
    allActivities.map((activity, index) => (
      <div key={index} className="form-check" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <label htmlFor={`activity-${index}`} className="form-check-label" style={{ marginLeft: '20px', marginRight:'30px' }}>
          {activity}
        </label>
        <input
          type="checkbox"
          id={`activity-${index}`}
          value={activity}
          onChange={handleActivityChange}
          className="form-check-input"
        />
      </div>
    ))
  )}
</div>
<br />


      <div className="form-group">
        <label htmlFor="beneficiariesCount" style={{ display: 'block', textAlign: 'right' }}> : عدد المستفيدين</label>
        <input
          type="text"
          id="beneficiariesCount"
          value={beneficiariesCount}
          onChange={handleBeneficiariesCountChange}
          className="form-control"
          placeholder="أدخل عدد المستفيدين"
          style={{ display: 'block', textAlign: 'right' }}
        />
        {error && <small className="text-danger">{error}</small>}
      </div>

      <div className="form-group">
        <label htmlFor="date" style={{ display: 'block', textAlign: 'right' }}> : التاريخ</label>
        <input
          type="date"
          id="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="additionalComments" style={{ display: 'block', textAlign: 'right' }}> : ملاحظات إضافية </label>
        <textarea
          id="additionalComments"
          value={additionalComments}
          onChange={(e) => setAdditionalComments(e.target.value)}
          className="form-control"
          placeholder="أدخل ملاحظاتك هنا"
          rows="4"
          style={{ display: 'block', textAlign: 'right' }}
          dir="rtl" 
        />
      </div>
      <br />

      <div className="form-group" style={{ textAlign: 'center' }}>
        <button  onClick={() => { handleSave(); handleGoAllFicheTechniques('AllFichesTechniques'); 
  }} className="btn btn-primary" style={{ marginRight: '10px' }}>
          حفظ
        </button>
        <button onClick={handleExportPDF} className="btn btn-secondary">
          تصدير إلى PDF
        </button>
      </div>
    </div>
  );
};

export default FicheTechnique;
