import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../../StylesCSS/EtablissementAccueil.css';
import { useUser } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../dashboardContent/config'; 


const EtablissementAccueil = ({ setActiveSection }) => {

  const { userId } = useUser();
  const [etablissements, setEtablissements] = useState([]);
  const [showAlertModal, setShowAlertModal] = useState(false); // Modal d'alerte
  const [showFormModal, setShowFormModal] = useState(false); // Modal pour le formulaire d'ajout
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false); // Modal de confirmation de suppression
  const [showEditModal, setShowEditModal] = useState(false); // Modal pour la modification
  const [newEtablissement, setNewEtablissement] = useState({
    commonCore: '',
    hostInstitution: '',
    town: '',
    regionalDirectorate: '',
    boarding: '',
    user: { id: userId }
  });
  const [selectedEtablissementId, setSelectedEtablissementId] = useState(null); // ID de l'établissement sélectionné pour la suppression
  const [selectedEtablissement, setSelectedEtablissement] = useState({}); // Établissement sélectionné pour la modification

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/etablissements/getEtablissementsByUser/${userId}`)
    .then((response) => response.json())
    .then((data) => {
      setEtablissements(data);
      if (data.length === 0) {
        setShowAlertModal(true); // Afficher le modal d'alerte s'il n'y a pas d'établissements
      }
    })
    .catch((error) => {
      console.error('There was an error fetching the data:', error);
    });
}, [userId]);

  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Inclure le userId dans newEtablissement
    const etablissementWithUserId = {
      ...newEtablissement, // Prendre les valeurs actuelles de l'établissement
      user: { id: userId }, // Ajouter l'ID utilisateur
    };
  
    fetch(`${API_BASE_URL}/api/etablissements/createEtablissements/${userId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(etablissementWithUserId), // Envoyer l'établissement avec l'ID utilisateur
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setEtablissements([...etablissements, data]); // Mettre à jour la liste des établissements
        setShowFormModal(false); // Fermer le modal
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };


  const handleDelete = (id) => {
    setSelectedEtablissementId(id); // Définir l'ID de l'établissement à supprimer
    setShowConfirmDeleteModal(true); // Afficher le modal de confirmation
  };

  const confirmDelete = () => {
    fetch(`${API_BASE_URL}/api/etablissements/deleteEtablissements/${selectedEtablissementId}`, {
      method: 'DELETE',
    }).then(() => {
      setEtablissements(etablissements.filter(etablissement => etablissement.id !== selectedEtablissementId));
      setShowConfirmDeleteModal(false); // Fermer le modal de confirmation après suppression
    });
  };

  const handleEdit = (etablissement) => {
    setSelectedEtablissementId(etablissement.id);
    console.log("id etablissement ", etablissement.id);
    setSelectedEtablissement(etablissement);
    setShowEditModal(true); // Ouvrir le modal de modification
  };
  useEffect(() => {
    console.log("ID de l'établissement sélectionné dans le modal:", selectedEtablissementId);
}, [selectedEtablissementId]);

  const handleEditSubmit = (event) => {
    event.preventDefault();
    fetch(`${API_BASE_URL}/api/etablissements/updateEtablissements/${selectedEtablissementId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedEtablissement),
    })
    .then((response) => {
      console.log("id",selectedEtablissementId);
        if (!response.ok) {
            throw new Error('Erreur de mise à jour');
        }
        return response.json();
    })
    .then((data) => {
       
        console.log('Données mises à jour reçues de l\'API:', data);

        const updatedEtablissements = etablissements.map(etablissement =>
            etablissement.id === selectedEtablissementId ? data : etablissement
        );
        setEtablissements(updatedEtablissements);
        setShowEditModal(false); 
    })
    .catch((error) => {
        console.error('Erreur:', error);
    });
};


const handleGoBack = (section) => {
  setActiveSection(section);
  console.log("Retour");
};
  return (
    <div className="etablissement-container">
      <h2 className="header-title" style={{ textAlign: 'right' }} > : مسك مؤسسات الاستقبال    </h2>
      <div className="buttoncontainer">
        <button onClick={() => handleGoBack('PageLayout2')} className="backbtn">
        <FontAwesomeIcon icon={faArrowLeft} /> رجوع
        </button>
        <button className="add-button1" onClick={() => setShowFormModal(true)}>إضافة مؤسسة جديدة</button>
    </div>
      <table className="styled-table">
        <thead>
          <tr>
          <th>تصرف</th> {/* Colonne pour les actions */}
            <th>الداخلية</th>
            <th>المديرية الاقليمية</th>
            <th>البلدة</th>
            <th>موسسة الاستقبال</th>
            <th>الجدع مشترك</th>
          </tr>
        </thead>
        <tbody>
          {etablissements.map((etablissement) => (
            <tr key={etablissement.id}>                 
            <td>
            <div className="button-container1">
            <button className="edit-button" onClick={() => handleEdit(etablissement)}>Modifier</button>
            <button className="delete-button" onClick={() => handleDelete(etablissement.id)}>Supprimer</button>
            </div>
            </td>
              <td>{etablissement.boarding}</td>
              <td>{etablissement.regionalDirectorate}</td>
              <td>{etablissement.town}</td>
              <td>{etablissement.hostInstitution}</td>
              <td>{etablissement.commonCore}</td>

            </tr>
          ))}
        </tbody>
      </table>


      {/* Modal pour le message "Aucun établissement trouvé" */}
      <Modal
        isOpen={showAlertModal}
        onRequestClose={() => setShowAlertModal(false)}
        contentLabel="Aucun établissement trouvé"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
          },
        }}
      >
        <h2 style={{ color: '#ff0000' }}>Il n'existe aucun établissement d'accueil</h2>
        <p>Vous pouvez saisir un nouvel établissement en cliquant sur le bouton ci-dessous.</p>
        <button className="modal-button" onClick={() => {
          setShowFormModal(true); // Ouvrir le modal du formulaire
          setShowAlertModal(false); // Fermer le modal d'alerte
        }}>Ajouter un nouvel établissement</button>
        <button className="modal-button" onClick={() => setShowAlertModal(false)}>Fermer</button>
      </Modal>

      {/* Modal pour le formulaire d'ajout d'établissement */}
      <Modal
  isOpen={showFormModal}
  onRequestClose={() => setShowFormModal(false)}
  contentLabel="Ajouter un établissement"
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      borderRadius: '10px',
      overflowY: 'auto',
      maxHeight: '80vh',
      maxWidth: '600px', // Définissez la largeur maximale ici
      width: '100%',
    },
  }}
>
  <div style={{ maxWidth: '600px', margin: 'auto' }}>
    <h2 style={{ textAlign: 'right' }}> : إضافة مؤسسة استقبال جديدة</h2>
    <form className="etablissement-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="commonCore" style={{ display: 'block', textAlign: 'right' }}>  : الجدع مشترك</label>
        <select id="commonCore" style={{ display: 'block', textAlign: 'right' }} value={newEtablissement.commonCore} onChange={(e) => setNewEtablissement({ ...newEtablissement, commonCore: e.target.value })}>
           {/* <option value="">يرجى اختيار خيار</option>
          <option value="ج.م. العلمي">ج.م.العلمي </option>
          <option value="ج.م. الادبي" >ج.م.الادبي</option>
          <option value="ج.م. التكنولوجي">ج.م.التكنولوجي</option>
          <option value="ج.م. الاصيل">ج.م.الاصيل</option>
          <option value="ج.م. العلمي تربية بدنبة">ج.م.العلمي تربية بدنبة</option>
          <option value="ج.م. الادبي تربية بدنية"> ج.م.الادبي تربية بدنية</option>
          <option value="ج.م. العلمي ثا الرياضيين"> ج.م.العلمي ثا الرياضيين</option>
          <option value="ج.م. الادبي ثا الرياضيين"> ج.م.الادبي ثا الرياضيين</option>
          <option value="ج.م. المهني صناعي "> ج.م. المهني صناعي  </option>
          <option value="ج.م. المهني خدماتي"> ج.م. المهني خدماتي </option> */}
          <option value="">يرجى اختيار خيار</option>
          <option value="الجذع المشترك للتعليم الأصيل">الجذع المشترك للتعليم الأصيل</option>
          <option value="الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية">الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية</option>
          <option value="الجذع المشترك للآداب والعلوم الإنسانية">الجذع المشترك للآداب والعلوم الإنسانية</option>
          <option value="الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية">الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية</option>
          <option value="الجذع المشترك العلمي للمسالك الدولية للبكالوريا المغربية">الجذع المشترك العلمي للمسالك الدولية للبكالوريا المغربية</option>
          <option value="الجذع المشترك العلمي">الجذع المشترك العلمي</option>
          <option value="الجذع المشترك العلمي تخصص تربية بدنية">الجذع المشترك العلمي تخصص تربية بدنية</option>
          <option value="الجذع المشترك التكنولوجي">الجذع المشترك التكنولوجي</option>
          <option value="الجذع المشترك الصناعي">الجذع المشترك الصناعي</option>
          <option value="الجذع المشترك الخدماتي">الجذع المشترك الخدماتي</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="hostInstitution" style={{ display: 'block', textAlign: 'right' }} > : مؤسسة الإستقبال  </label>
        <input id="hostInstitution" style={{ display: 'block', textAlign: 'right' }} type="text" value={newEtablissement.hostInstitution} onChange={(e) => setNewEtablissement({ ...newEtablissement, hostInstitution: e.target.value })} />
      </div>
      <div className="form-group">
        <label htmlFor="town" style={{ display: 'block', textAlign: 'right' }} > : البلدة </label>
        <input id="town" style={{ display: 'block', textAlign: 'right' }} type="text" value={newEtablissement.town} onChange={(e) => setNewEtablissement({ ...newEtablissement, town: e.target.value })} />
      </div>
      <div className="form-group">
        <label htmlFor="regionalDirectorate" style={{ display: 'block', textAlign: 'right' }} > : المديرية الاقليمية</label>
        <input id="regionalDirectorate" style={{ display: 'block', textAlign: 'right' }} type="text" value={newEtablissement.regionalDirectorate} onChange={(e) => setNewEtablissement({ ...newEtablissement, regionalDirectorate: e.target.value })} />
      </div>
      <div className="form-group">
        <label htmlFor="boarding" style={{ display: 'block', textAlign: 'right' }} > : الداخلية</label>
        <select id="boarding" style={{ display: 'block', textAlign: 'right' }} value={newEtablissement.boarding} onChange={(e) => setNewEtablissement({ ...newEtablissement, boarding: e.target.value })}>
          <option value="">يرجى اختيار خيار</option>
          <option value="نعم">نعم</option>
          <option value="لا">لا</option>
        </select>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
  <button
    className="modalbutton"
    onClick={() => setShowFormModal(false)}
    style={{ flex: 1, marginRight: '10px' }}
  >
    إغلاق
  </button>
  <button
    className="submitbutton"
    type="submit"
    style={{ flex: 1, marginLeft: '10px' }}
  >
    تأكيد
  </button>
</div>

    </form>
  </div>
</Modal>



      {/* Modal de confirmation de suppression */}
      <Modal
        isOpen={showConfirmDeleteModal}
        onRequestClose={() => setShowConfirmDeleteModal(false)}
        contentLabel="Confirmation de suppression"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
          }, 
        }}
      >
        <h4 style={{ textAlign: 'right' }} > : تأكيد الحذف</h4>
        <p>هل أنت متأكد أنك تريد حذف هذه المؤسسة؟</p>
        <button className="confirm-button" onClick={confirmDelete}>نعم</button>
        <button className="modal-button" onClick={() => setShowConfirmDeleteModal(false)}>لا</button>
      </Modal>

      {/* Modal pour le formulaire de modification d'établissement */}
      <Modal
  isOpen={showEditModal}
  onRequestClose={() => setShowEditModal(false)}
  contentLabel="Modifier un établissement"
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      borderRadius: '10px',
      overflowY: 'auto',
      maxHeight: '80vh',
      maxWidth: '600px', // Assure que les modaux ont la même largeur
      width: '100%',
    },
  }}
>
  <div style={{ maxWidth: '600px', margin: 'auto' }}>
    <h2 style={{ textAlign: 'right' }}>تعديل المؤسسة</h2>
    <form className="etablissement-form" onSubmit={handleEditSubmit}>
      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }}> الجدع مشترك:</label>
        <select value={selectedEtablissement.commonCore} onChange={(e) => setSelectedEtablissement({ ...selectedEtablissement, commonCore: e.target.value })} style={{ display: 'block', textAlign: 'right' }}>
        {/*   <option value="">يرجى اختيار خيار</option>
          <option value="ج.م. العلمي">ج.م.العلمي </option>
          <option value="ج.م. الادبي" >ج.م.الادبي</option>
          <option value="ج.م. التكنولوجي">ج.م.التكنولوجي</option>
          <option value="ج.م. الاصيل">ج.م.الاصيل</option>
          <option value="ج.م. العلمي تربية بدنبة">ج.م.العلمي تربية بدنبة</option>
          <option value="ج.م. الادبي تربية بدنية"> ج.م.الادبي تربية بدنية</option>
          <option value="ج.م. العلمي ثا الرياضيين"> ج.م.العلمي ثا الرياضيين</option>
          <option value="ج.م. الادبي ثا الرياضيين"> ج.م.الادبي ثا الرياضيين</option>
          <option value="ج.م. المهني صناعي "> ج.م. المهني صناعي  </option>
          <option value="ج.م. المهني خدماتي"> ج.م. المهني خدماتي </option> */}
          <option value="">يرجى اختيار خيار</option>
          <option value="الجذع المشترك للتعليم الأصيل">الجذع المشترك للتعليم الأصيل</option>
          <option value="الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية">الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية</option>
          <option value="الجذع المشترك للآداب والعلوم الإنسانية">الجذع المشترك للآداب والعلوم الإنسانية</option>
          <option value="الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية">الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية</option>
          <option value="الجذع المشترك العلمي للمسالك الدولية للبكالوريا المغربية">الجذع المشترك العلمي للمسالك الدولية للبكالوريا المغربية</option>
          <option value="الجذع المشترك العلمي">الجذع المشترك العلمي</option>
          <option value="الجذع المشترك العلمي تخصص تربية بدنية">الجذع المشترك العلمي تخصص تربية بدنية</option>
          <option value="الجذع المشترك التكنولوجي">الجذع المشترك التكنولوجي</option>
          <option value="الجذع المشترك الصناعي">الجذع المشترك الصناعي</option>
          <option value="الجذع المشترك الخدماتي">الجذع المشترك الخدماتي</option>
        </select>
      </div>
      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }}> موسسة الاستقبال:</label>
        <input type="text" value={selectedEtablissement.hostInstitution} onChange={(e) => setSelectedEtablissement({ ...selectedEtablissement, hostInstitution: e.target.value })} style={{ display: 'block', textAlign: 'right' }} />
      </div>
      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }}> البلدة:</label>
        <input type="text" value={selectedEtablissement.town} onChange={(e) => setSelectedEtablissement({ ...selectedEtablissement, town: e.target.value })} style={{ display: 'block', textAlign: 'right' }} />
      </div>
      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }}> المديرية الاقليمية:</label>
        <input type="text" value={selectedEtablissement.regionalDirectorate} onChange={(e) => setSelectedEtablissement({ ...selectedEtablissement, regionalDirectorate: e.target.value })} style={{ display: 'block', textAlign: 'right' }} />
      </div>
      <div className="form-group">
        <label style={{ display: 'block', textAlign: 'right' }}> الداخلية:</label>
        <select value={selectedEtablissement.boarding} onChange={(e) => setSelectedEtablissement({ ...selectedEtablissement, boarding: e.target.value })} style={{ display: 'block', textAlign: 'right' }}>
          <option value="">يرجى اختيار خيار</option>
          <option value="نعم">نعم</option>
          <option value="لا">لا</option>
        </select>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <button className="modalbutton" onClick={() => setShowEditModal(false)} style={{ flex: 1, marginRight: '10px' }}>إغلاق</button>
        <button className="submitbutton" type="submit" style={{ flex: 1, marginLeft: '10px' }}>تعديل</button>
      </div>
    </form>
  </div>
</Modal>

    </div>
  );
};

export default EtablissementAccueil;
