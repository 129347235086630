import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { submitForm, getFormByUserId, updateForm } from '../../api/formApi';
import { Modal, Button } from 'react-bootstrap';
import { API_BASE_URL } from '../dashboardContent/config'; 


const Form = () => {
  const [nom, setNom] = useState('');
  const [secteur, setSecteur] = useState('');
  const [institutions, setInstitutions] = useState(['']);
  const [annee, setAnnee] = useState('');
  const [direction_provinciale, setDirectionProvinciale] = useState('');
  const [direction_régionale, setDirectionRégionale] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalColor, setModalColor] = useState('green');
  const [isEdit, setIsEdit] = useState(false);  // Pour gérer l'état de modification
  const [formExists, setFormExists] = useState(false); // Pour savoir si un formulaire existe déjà

  const { userId } = useUser();

  useEffect(() => {
    const fetchFormData = async () => {
      if (userId) {
        try {
          const formData = await getFormByUserId(userId);
          if (formData) {
            setNom(formData.nom);
            setSecteur(formData.secteur);
            setInstitutions(formData.institutions.map(inst => inst.name));
            setAnnee(formData.annee);
            setDirectionProvinciale(formData.direction_provinciale);
            setDirectionRégionale(formData.direction_régionale);
            setFormExists(true);  // Indique qu'un formulaire existe
          }
        } catch (error) {
          console.error(error);
          setModalMessage('المرجو ادخال المعلومات الخاصة بك');
          setModalColor('red');
          setShowModal(true);
        }
      }
    };

    fetchFormData();
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!nom || !secteur || institutions.some(inst => !inst) || !annee || !direction_provinciale || !direction_régionale) {
      setModalMessage('يجب ملء جميع الخانات');
      setModalColor('red');
      setShowModal(true);
      return;
    }

    if (!userId) {
      setModalMessage('Utilisateur non connecté.');
      setModalColor('red');
      setShowModal(true);
      return;
    }

    try {
      const formData = {
        nom,
        secteur,
        institutions: institutions.map(name => ({ name, user: { id: userId } })),
        annee,
        direction_provinciale,
        direction_régionale,
        user: { id: userId }
      };

      if (formExists) {
        console.log("formdata update", formData);

        await updateForm(userId ,formData);  // Mettre à jour le formulaire existant
        console.log("formdata update", formData);
        setModalMessage('تم تعديل البيانات بنجاح.');
      } else {
        await submitForm(formData);  // Soumettre un nouveau formulaire
        setModalMessage('تم تسجيل البيانات بنجاح.');
        setFormExists(true);
      }

      setModalColor('green');
      setShowModal(true);
      setIsEdit(false);
    } catch (error) {
      console.error(error);
      setModalMessage('Erreur lors de l\'envoi du formulaire.');
      setModalColor('red');
      setShowModal(true);
    }
  };

  const handleEditClick = () => {
    setIsEdit(true);  // Activer le mode modification
    setShowModal(true);  // Afficher le modal
  };

  const handleAddInstitution = () => {
    setInstitutions([...institutions, '']);
  };

  const handleRemoveInstitution = (index) => {
    const newInstitutions = institutions.filter((_, i) => i !== index);
    setInstitutions(newInstitutions);
  };

  const handleInstitutionChange = (index, value) => {
    const newInstitutions = [...institutions];
    newInstitutions[index] = value;
    setInstitutions(newInstitutions);
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto' }}>
      <h2 style={{ textAlign: 'right' }}>المعلومات</h2>
      {!formExists ? (
        <form onSubmit={handleSubmit}>
          <div className="form-group" style={{ marginBottom: '15px' }}>
            <label htmlFor="nom" style={{ display: 'block', textAlign: 'right' }}> : الاسم</label>
            <input
              type="text"
              className="form-control"
              id="nom"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              style={{ width: '100%' }}
              required
            />
          </div>
          <div className="form-group" style={{ marginBottom: '15px' }}>
            <label htmlFor="secteur" style={{ display: 'block', textAlign: 'right' }}> : القطاع</label>
            <input
              type="text"
              className="form-control"
              id="secteur"
              value={secteur}
              onChange={(e) => setSecteur(e.target.value)}
              style={{ width: '100%' }}
              required
            />
          </div>
          <div className="form-group" style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', textAlign: 'right' }}> : المؤسسات</label>
            {institutions.map((institution, index) => (
              <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  className="form-control"
                  value={institution}
                  onChange={(e) => handleInstitutionChange(index, e.target.value)}
                  style={{ flex: 1, marginRight: '10px' }}
                  required
                />
                {index === institutions.length - 1 && (
                  <button
                    type="button"
                    onClick={handleAddInstitution}
                    className="btn btn-secondary"
                    style={{ marginRight: '5px' }}
                  >
                    +
                  </button>
                )}
                {institutions.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveInstitution(index)}
                    className="btn btn-danger"
                  >
                    -
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="form-group" style={{ marginBottom: '15px' }}>
            <label htmlFor="annee" style={{ display: 'block', textAlign: 'right' }}> : السنة الدراسية</label>
            <input
              type="text"
              className="form-control"
              id="annee"
              value={annee}
              onChange={(e) => setAnnee(e.target.value)}
              style={{ width: '100%' }}
              required
            />
          </div>
          <div className="form-group" style={{ marginBottom: '15px' }}>
            <label htmlFor="direction_provinciale" style={{ display: 'block', textAlign: 'right' }}> : المديرية الاقليمية </label>
            <input
              type="text"
              className="form-control"
              id="direction_provinciale"
              value={direction_provinciale}
              onChange={(e) => setDirectionProvinciale(e.target.value)}
              style={{ width: '100%' }}
              required
            />
          </div>
          <div className="form-group" style={{ marginBottom: '15px' }}>
            <label htmlFor="direction_régionale" style={{ display: 'block', textAlign: 'right' }}> : المديرية الجهوية </label>
            <input
              type="text"
              className="form-control"
              id="direction_régionale"
              value={direction_régionale}
              onChange={(e) => setDirectionRégionale(e.target.value)}
              style={{ width: '100%' }}
              required
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <button type="submit" className="btn btn-primary">تسجيل</button>
          </div>
        </form>
      ) : (
        <>
 <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px', border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>تفاصيل المعلومات </h2>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
          <strong style={{ color: '#555',display: 'block', textAlign: 'right'  }}>  : الاسم</strong> <span style={{ display: 'block', textAlign: 'right' }}>{nom}</span>
        </div>
        <div style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
          <strong style={{ color: '#555' , display: 'block', textAlign: 'right'}} > : القطاع</strong> <span style={{ display: 'block', textAlign: 'right' }}>{secteur}</span>
        </div>
        <div style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
          <strong style={{ color: '#555' , display: 'block', textAlign: 'right'}} > : المؤسسات</strong> <span style={{ display: 'block', textAlign: 'right' }}>{institutions.join(', ')}</span>
        </div>
        <div style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
          <strong style={{ color: '#555', display: 'block', textAlign: 'right' }} > : السنة الدراسية</strong> <span style={{ display: 'block', textAlign: 'right' }}>{annee}</span>
        </div>
        <div style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
          <strong style={{ color: '#555' , display: 'block', textAlign: 'right' }}> : المديرية الاقليمية</strong> <span style={{ display: 'block', textAlign: 'right' }}>{direction_provinciale}</span>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <strong style={{ color: '#555' , display: 'block', textAlign: 'right' }} > : الأكاديمية الجهوية </strong> <span style={{ display: 'block', textAlign: 'right' }}>{direction_régionale}</span>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <button onClick={handleEditClick} style={{ padding: '10px 20px', border: 'none', borderRadius: '4px', backgroundColor: '#007bff', color: '#fff', cursor: 'pointer' }}>
          تعديل
        </button>
      </div>
    </div>
        </>
      )}

      {/* Modal pour modification */}
      <Modal show={isEdit} onHide={() => setIsEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل المعلومات</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group" style={{ marginBottom: '15px' }}>
              <label htmlFor="nom" style={{ display: 'block', textAlign: 'right' }}> : الاسم</label>
              <input
                type="text"
                className="form-control"
                id="nom"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                style={{ width: '100%' }}
                required
              />
            </div>
            <div className="form-group" style={{ marginBottom: '15px' }}>
              <label htmlFor="secteur" style={{ display: 'block', textAlign: 'right' }}> : القطاع</label>
              <input
                type="text"
                className="form-control"
                id="secteur"
                value={secteur}
                onChange={(e) => setSecteur(e.target.value)}
                style={{ width: '100%' }}
                required
              />
            </div>
            <div className="form-group" style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', textAlign: 'right' }}> : المؤسسات</label>
              {institutions.map((institution, index) => (
                <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                  <input
                    type="text"
                    className="form-control"
                    value={institution}
                    onChange={(e) => handleInstitutionChange(index, e.target.value)}
                    style={{ flex: 1, marginRight: '10px' }}
                    required
                  />
                  {index === institutions.length - 1 && (
                    <button
                      type="button"
                      onClick={handleAddInstitution}
                      className="btn btn-secondary"
                      style={{ marginRight: '5px' }}
                    >
                      +
                    </button>
                  )}
                  {institutions.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveInstitution(index)}
                      className="btn btn-danger"
                    >
                      -
                    </button>
                  )}
                </div>
              ))}
            </div>
            <div className="form-group" style={{ marginBottom: '15px' }}>
              <label htmlFor="annee" style={{ display: 'block', textAlign: 'right' }}> : السنة الدراسية</label>
              <input
                type="text"
                className="form-control"
                id="annee"
                value={annee}
                onChange={(e) => setAnnee(e.target.value)}
                style={{ width: '100%' }}
                required
              />
            </div>
            <div className="form-group" style={{ marginBottom: '15px' }}>
              <label htmlFor="direction_provinciale" style={{ display: 'block', textAlign: 'right' }}> : المديرية الاقليمية </label>
              <input
                type="text"
                className="form-control"
                id="direction_provinciale"
                value={direction_provinciale}
                onChange={(e) => setDirectionProvinciale(e.target.value)}
                style={{ width: '100%' }}
                required
              />
            </div>
            <div className="form-group" style={{ marginBottom: '15px' }}>
              <label htmlFor="direction_régionale" style={{ display: 'block', textAlign: 'right' }}> : الأكاديمية الجهوية </label>
              <input
                type="text"
                className="form-control"
                id="direction_régionale"
                value={direction_régionale}
                onChange={(e) => setDirectionRégionale(e.target.value)}
                style={{ width: '100%' }}
                required
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <button type="submit" className="btn btn-primary">تحديث</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Modal pour messages de confirmation */}
      <Modal show={modalMessage} onHide={() => setModalMessage('')}>
        <Modal.Header closeButton>
          <Modal.Title>ملاحظة</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center', color: modalColor }}>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalMessage('')}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Form;
